<template>
  <v-container id="wrapper" class="align-center" fluid fill-height>
    <v-layout class="align-center justify-center">
      <v-flex>
        <slot />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "BaseCenterWrapper",
};
</script>
