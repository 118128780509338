<template>
  <div>
    <v-data-table
      class="custom-table custom-scrollbar"
      mobile-breakpoint="0"
      hide-default-footer
      fixed-header
      :loading="loading"
      :headers="headers"
      :items="items"
      :height="height"
      :search="search"
      :custom-filter="customFilter"
      :key="JSON.stringify(footerOptions)"
      :options.sync="footerOptions"
      v-on="$listeners"
      @pagination="footerPagination = $event"
    >
      <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
        <slot :name="name" v-bind="item"></slot>
      </template>

      <!-- table data logs -->
      <template v-slot:item.log_type="{ item }">
        <v-chip color="#eef5f1" text-color="#579f6e" label small>{{
          item.log_type
        }}</v-chip>
      </template>
      <template v-slot:item.log_field="{ item }">
        <v-chip color="#eaf7fd" text-color="#30aeeb" label small>{{
          item.log_field
        }}</v-chip>
      </template>
    </v-data-table>

    <v-data-footer
      :class="{ 'is-hide': hidePagination }"
      show-current-page
      :pagination="footerPagination"
      :options="footerOptions"
      :items-per-page-options="itemsPerPageOptions"
      :items-per-page-all-text="itemsPerPageAllText"
      @update:options="
        footerOptions = $event;
        $emit('update:items-per-page', $event.itemsPerPage);
      "
    >
      <template #prepend>
        <slot v-if="hidePagination" name="pagination"></slot>
      </template>
    </v-data-footer>
  </div>
</template>

<script>
export default {
  name: "TableData",
  data() {
    return {
      footerOptions: {
        page: 1,
        itemsPerPage: this.itemsPerPageOptions[0],
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      footerPagination: {
        page: null,
        itemsPerPage: null,
        pageStart: null,
        pageStop: null,
        pageCount: null,
        itemsLength: null,
      },
    };
  },
  // props: ["headers", "items", "itemsPerPageOptions", "itemsPerPageAllText"],
  props: {
    headers: {
      type: Array,
    },
    items: {
      type: Array,
    },
    itemsPerPageOptions: {
      type: Array,
      default: [5, 10, 20, -1],
    },
    itemsPerPageAllText: {
      type: String,
      default: "All",
    },
    height: {
      type: [String, Number],
    },
    elevation: {
      type: String,
    },
    hidePagination: {
      type: Boolean,
    },
    customFilter: {
      type: Object,
    },
    search: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
  },
  // ---- for testing event
  methods: {
    check(val) {
      console.log(val);
    },
  },
};
</script>

<style lang="scss">
//   .custom-table {
//     border: 1px solid rgba(224, 224, 224, 1) !important;
//     border-radius: 1rem !important;
//     background: var(--v-accent-base) !important;

//     .v-data-table__wrapper {
//       border-radius: 1rem;

//       table {
//         // white-space: nowrap !important;

//         thead th {
//           // background: rgba(224, 224, 224, 0.49) !important;
//           background: #eaebef !important;

//           &:first-child {
//             border-radius: 12px 0 0 0;
//           }
//           &:last-child {
//             border-radius: 0 12px 0 0;
//           }
//         }

//         td {
//           border-bottom: 1px solid rgb(224, 224, 224) !important;
//         }

//         tbody tr:nth-of-type(even) {
//           background-color: #ecf1fd !important;
//           transition: 0.2s;
//           border: 0;
//           height: 70px;

//           &:hover {
//             transition: 0.2s;
//             background-color: #dce2ef !important;
//           }
//         }

//         tbody tr:nth-of-type(odd) {
//           background-color: #f4f6fd;
//           transition: 0.2s;
//           border: 0;
//           height: 70px;

//           &:hover {
//             transition: 0.2s;
//             background-color: #eaecf3 !important;
//           }
//         }
//       }
//     }
//   }

.v-data-footer {
  &.is-hide {
    span {
      display: none;
    }

    .v-data-footer__ {
      &pagination {
        display: none;
      }
      &icons-before {
        display: none;
      }
      &icons-after {
        display: none;
      }
    }
  }
}

.custom-table td {
  color: #4d5359;
}

/* scrollbar table */
.custom-scrollbar ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.custom-scrollbar ::-webkit-scrollbar-track {
  background: transparent;
}
.custom-scrollbar ::-webkit-scrollbar-thumb {
  background: #cac6c6;
  border-radius: 6px;
}
.custom-scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #a29c9c;
}
</style>
