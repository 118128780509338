import AlertDialog from './alert-dialog.vue'
import { events } from './events'

export default {
  install(Vue, args = {}) {
    if (this.installed) {
      return
    }

    this.installed = true
    this.params = args

    Vue.component(args.componentName || 'alert-dialog', AlertDialog)

    const alert = params => {
      if (typeof params != 'object' || Array.isArray(params)) {
        let caughtType = typeof params
        if (Array.isArray(params)) caughtType = 'array'

        throw new Error(
          `Options type must be an object. Caught: ${caughtType}. Expected: object`
        )
      }

      if (typeof params === 'object') {
        if (
          params.hasOwnProperty('callback') &&
          typeof params.callback != 'function'
        ) {
          let callbackType = typeof params.callback
          throw new Error(
            `Callback type must be an function. Caught: ${callbackType}. Expected: function`
          )
        } else if (!params.hasOwnProperty('type')) {
          throw new Error(
            `Must define type. (ex: success, error, warning, confirmation)`
          )
        } else if (!params.hasOwnProperty('button')) {
          throw new Error(
            `Must define button property (button : {yes : 'yes'})`
          )
        }

        events.$emit('open', params)
      }
    }

    const name = args.prefixedName || 'alert'
    Vue.prototype['$' + name] = alert
    Vue[name] = alert
  }
}
