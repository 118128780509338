import Vue from "vue";
import VueRouter from "vue-router";
import { getAccount } from "@/helpers/account";

import AuthLayout from "@/components/layouts/auth-layout";
import DashboardLayout from "@/components/layouts/dashboard-layout";
import LoginView from "@/views/auth/login.vue";
import DashboardView from "@/views/dashboard";
import AssetView from "@/views/asset";
import stationaryView from "@/views/stationary";
import UserManagementView from "@/views/user-management";
import QRasset from "@/views/qr/detail-asset";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: AuthLayout,
    redirect: "/login",
    meta: {
      hideForAuth: true,
    },
    children: [
      {
        path: "login",
        name: "login",
        component: LoginView,
      },
    ],
  },
  {
    path: "/dashboard",
    component: DashboardLayout,
    redirect: "/dashboard/overview",
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "overview",
        name: "overview",
        component: DashboardView,
      },
      {
        path: "assets",
        name: "assets",
        component: AssetView,
        meta: {
          visibility: "is_asset_feature",
        },
      },
      {
        path: "stationary-medicine",
        name: "stationary & medicine",
        component: stationaryView,
        meta: {
          visibility: "is_stationary_feature",
        },
      },
    ],
  },
  {
    path: "/user-management",
    component: DashboardLayout,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
    children: [
      {
        path: "",
        name: "user management",
        component: UserManagementView,
      },
    ],
  },
  {
    path: "/view/:assetID",
    name: "detail asset from qr",
    component: QRasset,
    meta: {
      noAuth: true,
    },
  },
  {
    path: "*",
    redirect: "/login",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let local = !!localStorage.getItem("user");
  let session = !!sessionStorage.getItem("user");

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!session) {
      next({ name: "login" });
    } else {
      next();
    }
  }

  if (to.matched.some((record) => record.meta.noAuth)) {
    next();
  }

  if (to.matched.some((record) => record.meta.hideForAuth)) {
    if (sessionStorage.getItem("user")) {
      next({ name: "overview" });
    } else {
      next();
    }
  }

  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (getAccount().level == 4) {
      next({ name: "overview" });
    } else {
      next();
    }
  }

  if (router.currentRoute.name !== "login") {
    if (getAccount().menu[router.currentRoute?.meta?.visibility] ?? true) {
      next();
    } else {
      next({ name: "overview" });
    }
  }
});

export default router;
