<template>
  <v-dialog
    overlay-opacity="0.5"
    content-class="elevation-0"
    scrollable
    :max-width="$vuetify.breakpoint.xs ? '100%' : '65%'"
    v-model="openDialog"
  >
    <BaseCard>
      <template #title>
        Asset Purchase on {{ events.title | moment("MMMM DD, YYYY") }}
      </template>

      <v-container>
        <v-row>
          <v-col
            v-for="col of resData.data.list_data"
            :key="col.id"
            cols="12"
            sm="4"
          >
            <BaseCard
              class="d-flex flex-column justify-space-between"
              :height="'100%'"
              outlined
            >
              <v-container>
                <p>{{ col.number }}</p>
                <p>
                  {{
                    col.company == "ET"
                      ? "Ebdesk Teknologi"
                      : col.company == "I2"
                      ? "Indonesia Indicator"
                      : "Rekarana Cipta Anugrah"
                  }}
                </p>
                <p style="text-transform: capitalize">
                  {{ col.location }}
                </p>
              </v-container>

              <template #action>
                <div
                  class="d-flex justify-space-between"
                  :style="{ width: '100%' }"
                >
                  <p>{{ col.created_at | moment("YYYY-MM-DD") }}</p>
                  <p>{{ col.ip_address }}</p>
                </div>
              </template>
            </BaseCard>
          </v-col>
        </v-row>
      </v-container>

      <template #action v-if="events.filter">
        <div
          v-if="!isNaN(paginationLength)"
          class="d-flex justify-space-between"
          style="width: 100%"
        >
          <div>
            <BaseSelect
              label="Cols per page"
              height="40"
              :items="[9, 15, 24]"
              v-model="filter.size"
              @change="openModal(events)"
            ></BaseSelect>
          </div>

          <v-pagination
            color="primary"
            :length="paginationLength"
            :total-visible="5"
            v-model="filter.page"
            @input="openModal(events)"
          ></v-pagination>
        </div>
      </template>
    </BaseCard>
  </v-dialog>
</template>

<script>
import axios from "axios";
import BaseCard from "@/components/base/card/base-card.vue";
import BaseSelect from "@/components/base/input/base-select.vue";
import Tabs from "@/components/base/tabs/Tabs.vue";

import { events } from "./index";
import moment from "moment";

export default {
  name: "detail-asset-purchase",
  data() {
    return {
      openDialog: false,
      events: {},
      resData: {
        data: [],
        loading: false,
      },
      filter: {
        page: 1,
        size: 9,
      },
    };
  },
  components: {
    BaseCard,
    BaseSelect,
    Tabs,
  },
  computed: {
    paginationLength() {
      return Math.ceil(this.resData.data.total / this.filter.size) ?? 1;
    },
  },
  mounted() {
    events.$on("open", this.openModal);
  },
  methods: {
    openModal(params) {
      this.openDialog = true;
      this.events = params;
      this.resData = { ...this.resData, data: [], loading: true };
      let { interval, ...rest } = params.params;
      let param = {};

      switch (interval) {
        case "1M":
          param["start"] = moment(params.title)
            .startOf("month")
            .format("YYYY-MM-DD");
          param["end"] = moment(params.title)
            .endOf("month")
            .format("YYYY-MM-DD");
          break;

        case "1d":
          param["start"] = moment(params.title)
            .startOf("day")
            .format("YYYY-MM-DD");
          param["end"] = moment(params.title).endOf("day").format("YYYY-MM-DD");
          break;

        case "1y":
          param["start"] = moment(params.title)
            .startOf("year")
            .format("YYYY-MM-DD");
          param["end"] = moment(params.title)
            .endOf("year")
            .format("YYYY-MM-DD");
          break;

        default:
          break;
      }

      axios({
        method: "GET",
        url: `/eb-api/eb-asset/get-asset`,
        params: {
          ...param,
          page: this.filter.page,
          size: this.filter.size,
        },
      }).then((resp) => {
        let res = resp.data.data;
        this.resData = { ...this.resData, data: res, loading: false };
      });
    },
  },
};
</script>

<style></style>
