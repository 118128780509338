

import axios from "axios";

export default {
  namespaced: true,
  state: {
    countData: {
      hardware: 0,
      software: 0
    },
  },
  actions: {
    async countAsset({ state }, data) {
      try {
        await axios({
          method: "GET",
          url: "/eb-api/eb-asset/count-asset",
          params: {
            type: data
          }
        }).then((res) => {
            let resp = res.data.data;
            state.countData[data] = resp;

            console.log(state.countData);
        });
      } catch (error) {}
    },
  },
};
