import axios from "axios";

export default {
  namespaced: true,
  state: {
    statusTableData: {
      data: [],
      loading: false,
    },
  },
  actions: {
    async statusTableAsset({ state }) {
      state.statusTableData = {
        ...state.statusTableData,
        loading: true,
      };

      try {
        await axios({
          method: "GET",
          url: "/eb-api/eb-asset/status-asset-table",
        }).then((res) => {
          let resp = res.data.data;

          let result = [];
          resp.map((a) => {
            let total =
              parseInt(a.dipakai) +
              parseInt(a.rusak) +
              parseInt(a.diperbaiki) +
              parseInt(a.tersedia);

            result.push({
              name: a.name,
              used: a.dipakai,
              damaged: a.rusak,
              fixing: a.diperbaiki,
              available: a.tersedia,
              total: total,
            });
          });

          state.statusTableData.data = result;
        });

        state.statusTableData = {
          ...state.statusTableData,
          loading: false,
        };
      } catch (error) {
        state.statusTableData = {
          ...state.statusTableData,
          loading: false,
        };
      }
    },
  },
};
