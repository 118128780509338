<template>
  <div class="d-flex flex-column gap-3">
    <AddUserDialog v-model="showAdd" @onsuccess="getAllUser()" />
    <EditUserDialog
      v-model="showEditDialog.show"
      :params="showEditDialog.params"
      @onsuccess="getAllUser()"
    />
    <div class="d-flex justify-end">
      <BaseButton
        title="Add New User"
        height="40px"
        icon-append="iconoir-plus"
        color="primary"
        depressed
        rounded
        @click="showAdd = !showAdd"
      />
    </div>
    <TableData
      :headers="header"
      :items="user"
      :itemsPerPageOptions="[5, 10, 20, -1]"
    >
      <template v-slot:[`item.avatar`]="{ item }">
        <v-avatar
          class="ma-auto mx-3"
          :color="stringToColor(item.username)"
          size="40"
        >
          <span class="white--text">{{
            item.username.split(" ")[0][1] | uppercase
          }}</span>
        </v-avatar>
      </template>
      <template v-slot:[`item.level`]="{ item }">
        <p style="font-size: 14px">
          {{ item.level == 1 ? "Admin" : item.level == 4 ? "User" : "" }}
        </p>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <p style="font-size: 14px">
          {{ item.created_at | moment("MMMM DD, YYYY") }}
        </p>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex flex-row justify-center">
          <BaseButtonIcon
            icon="iconoir-trash"
            color="red"
            depressed
            @click.stop="deleteUser(item)"
          />
          <BaseButtonIcon
            icon="iconoir-edit-pencil"
            color="blue"
            @click.stop="
              showEditDialog.show = true;
              showEditDialog.params = item;
            "
          />
        </div>
      </template>
    </TableData>
  </div>
</template>

<script>
import BaseCard from "@/components/base/card/base-card.vue";
import TableData from "@/components/base/table/table-data.vue";
import BaseButton from "@/components/base/button/base-button.vue";
import BaseButtonIcon from "@/components/base/button/base-button-icon.vue";
import AddUserDialog from "./component/add-user-dialog.vue";
import EditUserDialog from "./component/edit-user-dialog.vue";
import axios from "axios";

export default {
  components: {
    BaseCard,
    TableData,
    BaseButton,
    BaseButtonIcon,
    AddUserDialog,
    EditUserDialog,
  },
  data() {
    return {
      user: [],
      showEditDialog: {
        show: false,
        params: {},
      },
      showAdd: false,
      header: [
        {
          text: "Avatar",
          value: "avatar",
          align: "left",
          width: "10%",
          sortable: false,
        },
        {
          text: "Username",
          value: "username",
          align: "left",
          width: "15%",
        },
        {
          text: "Fullname",
          value: "fullname",
          align: "left",
          width: "15%",
        },
        {
          text: "Email",
          value: "email",
          align: "left",
          width: "15%",
        },
        {
          text: "Level",
          value: "level",
          align: "left",
          width: "15%",
        },
        {
          text: "Created At",
          value: "created_at",
          align: "left",
          width: "15%",
        },
        {
          text: "Action",
          value: "action",
          align: "center",
          width: "15%",
          sortable: false,
        },
      ],
    };
  },
  created() {
    this.getAllUser();
  },
  methods: {
    stringToColor(string) {
      let hash = 0;
      let i;
      for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }
      let color = "#";
      for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `0f${value.toString(16)}`.substr(-2);
      }
      return color;
    },
    getAllUser() {
      axios({
        method: "GET",
        url: "/eb-api/ebasset-management/user/show",
        params: {
          username: "ALL",
          from: 0,
          limit: 100,
          sort: "ASC",
        },
      }).then((res) => {
        this.user = res.data.data.data;
      });
    },
    deleteUser(e) {
      this.$alert({
        type: "confirmation",
        title: "Confirmation",
        message: `Are yout sure want to delete ${e.username} ?`,
        button: {
          yes: "yes",
          no: "no",
        },
        callback: () => {
          axios({
            method: "DELETE",
            url: `/eb-api/ebasset-management/user/delete/${e.username}`,
          })
            .then((res) => {
              setTimeout(() => {
                this.getAllUser();
              }, 800);
            })
            .catch((err) => {
              this.$alert({
                type: "warning",
                title: "Warning",
                message: `Failed to delete ${e.username} ?`,
                button: {
                  yes: "yes",
                },
              });
            });
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
