import axios from "axios";

export default {
  namespaced: true,
  state: {
    brandData: {
      data: [],
      loading: false,
    },
  },
  actions: {
    async brandAsset({ state }) {
      state.brandData = {
        ...state.brandData,
        loading: true,
      };

      try {
        await axios({
          method: "GET",
          url: "/eb-api/eb-asset/brand-asset",
        }).then((res) => {
          let resp = res.data.data;

          let result = [];
          resp.map((a) => result.push({ name: a.brand, value: a.count }));

          state.brandData.data = result;
        });

        state.brandData = {
          ...state.brandData,
          loading: false,
        };
      } catch (error) {
        state.brandData = {
          ...state.brandData,
          loading: false,
        };
      }
    },
  },
};
