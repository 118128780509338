import Vue from "vue";
import Vuex from "vuex";
import getAsset from "./modules/assetController/getAsset";
import getClassification from "./modules/assetController/getClassification";
import getClassificationAtk from "./modules/atkController/getClassificationAtk";
import getAtk from "./modules/atkController/getAtk";
import brandAsset from "./modules/dashboardController/brandAsset";
import categoryAsset from "./modules/dashboardController/categoryAsset";
import countAsset from "./modules/dashboardController/countAsset";
import employeeTableAsset from "./modules/dashboardController/employeeTableAsset";
import purchaseAsset from "./modules/dashboardController/purchaseAsset";
import serverTableAsset from "./modules/dashboardController/serverTableAsset";
import statusAsset from "./modules/dashboardController/statusAsset";
import statusTableAsset from "./modules/dashboardController/statusTableAsset";
import GatewayLogin from "./modules/gateway/login";
import drawer from "./modules/drawer/drawer";
import getEmployee from "./modules/getEmployee/getEmployee";
import generateQR from "./modules/qrCode/generateQR";
import getAssetQR from "./modules/qrCode/getAssetQR";

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  modules: {
    gatewayLogin: GatewayLogin,
    dashboardPurchase: purchaseAsset,
    dashboardCountAsset: countAsset,
    dashboardCategory: categoryAsset,
    dashboardBrand: brandAsset,
    dashboardStatus: statusAsset,
    dashboardEmployeeTableAsset: employeeTableAsset,
    dashboardServerTableAsset: serverTableAsset,
    dashboardStatusTableAsset: statusTableAsset,
    assetGetAsset: getAsset,
    assetGetClassification: getClassification,
    assetGetClassificationAtk: getClassificationAtk,
    stationaryGetAtk: getAtk,
    getEmployee: getEmployee,
    generateQR: generateQR,
    getAssetByQR: getAssetQR,
    drawer: drawer,
  },
});
