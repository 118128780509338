var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DialogCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Add New User ")]},proxy:true},{key:"action",fn:function(){return [_c('v-container',{staticClass:"d-flex flex-row gap-2"},[_c('BaseButton',{attrs:{"title":"Submit","color":"primary","height":"40px","depressed":"","rounded":""},on:{"click":function($event){return _vm.handleSubmit()}}}),_c('BaseButton',{attrs:{"title":"Cancel","height":"40px","color":"primary","outlined":"","depressed":"","rounded":""},on:{"click":function($event){_vm.showDialog = !_vm.showDialog}}})],1)]},proxy:true}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('BaseTextField',{attrs:{"height":"20px","outlined":"","rules":[(v) => !!v || 'username is required']},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}},[_c('p',[_vm._v("Username "),_c('b',{staticClass:"red--text"},[_vm._v("*")])])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('BaseTextField',{attrs:{"height":"20px","outlined":"","rules":[(v) => !!v || 'fullname is required']},model:{value:(_vm.form.fullname),callback:function ($$v) {_vm.$set(_vm.form, "fullname", $$v)},expression:"form.fullname"}},[_c('p',[_vm._v("Full Name "),_c('b',{staticClass:"red--text"},[_vm._v("*")])])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('BaseTextField',{attrs:{"height":"20px","outlined":"","hide-details":"auto","rules":[
            (v) => !!v || 'email is required',
            (v) =>
              !v ||
              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
              'E-mail must be valid',
          ]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}},[_c('p',[_vm._v("Email "),_c('b',{staticClass:"red--text"},[_vm._v("*")])])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('BaseSelect',{attrs:{"height":"20px","outlined":"","item-text":"name","item-value":"value","items":[
            { name: 'Admin', value: 1 },
            { name: 'User', value: 4 },
          ],"rules":[(v) => !!v || 'level is required']},model:{value:(_vm.form.level),callback:function ($$v) {_vm.$set(_vm.form, "level", $$v)},expression:"form.level"}},[_c('p',[_vm._v("Level "),_c('b',{staticClass:"red--text"},[_vm._v("*")])])])],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('BaseCheckbox',{attrs:{"label":"Asset Feature","rules":[(v) => !!v || 'level is required']},model:{value:(_vm.form.menu.is_asset_feature),callback:function ($$v) {_vm.$set(_vm.form.menu, "is_asset_feature", $$v)},expression:"form.menu.is_asset_feature"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('BaseCheckbox',{attrs:{"label":"Stationary Feature","rules":[(v) => !!v || 'level is required']},model:{value:(_vm.form.menu.is_stationary_feature),callback:function ($$v) {_vm.$set(_vm.form.menu, "is_stationary_feature", $$v)},expression:"form.menu.is_stationary_feature"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }