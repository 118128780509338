<template>
  <div v-if="isShow" class="vc-overlay" id="vueConfirm">
    <v-dialog
      persistent
      content-class="elevation-0"
      overlay-opacity="0.4"
      width="500"
      v-model="isShow"
    >
      <v-card class="card">
        <v-card-title>
          <div class="d-flex">
            <v-icon class="dialog-title-icon mr-2" :color="color">
              {{ icon }}
            </v-icon>
            <h3>{{ dialog.title }}</h3>
          </div>
          <div class="ml-auto">
            <v-btn small icon @click="isShow = false"
              ><v-icon small>mdi-close</v-icon></v-btn
            >
          </div>
        </v-card-title>

        <v-card-text
          ><p>{{ dialog.message }}</p>
        </v-card-text>

        <v-card-actions>
          <v-checkbox
            v-if="dialog.checkboxLabel != ''"
            class="checkbox mt-0 pt-0"
            hide-details
            hide-spin-buttons
            :ripple="false"
            :label="dialog.checkboxLabel"
            v-model="dialog.checkbox"
          >
          </v-checkbox>
          <v-spacer></v-spacer>
          <v-hover v-if="dialog.button.no" v-slot="{ hover }">
            <v-btn
              class="button"
              color="#a9a9a9"
              dark
              rounded
              :elevation="0"
              :outlined="hover"
              @click="isShow = false"
              >{{ dialog.button.no }}</v-btn
            >
          </v-hover>
          <v-hover v-if="dialog.button.yes" v-slot="{ hover }">
            <!-- :disabled="dialog.checkboxLabel ? !dialog.checkbox : false" -->
            <v-btn
              class="button"
              dark
              rounded
              :color="color"
              :elevation="0"
              :outlined="hover"
              @click.stop="(e) => handleClickButton(e, true)"
              >{{ dialog.button.yes }}</v-btn
            >
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { events } from "./events";

Vue.directive("focus", {
  inserted: function (el) {
    el.focus();
  },
});

const Component = {
  name: "AlertDialog",
  data() {
    return {
      isShow: false,
      dialog: {
        checkboxLabel: "",
        checkbox: false,
        title: "",
        message: "",
        button: {},
      },
      params: {},
    };
  },
  computed: {
    icon() {
      let type = this.params.type.toLowerCase();
      if (type == "success") {
        return "mdi-check-circle-outline";
      } else if (type == "warning") {
        return "mdi-information-outline";
      } else if (type == "confirmation") {
        return "mdi-help-circle-outline";
      }
    },

    color() {
      let type = this.params.type.toLowerCase();
      if (type == "success") {
        return "success";
      } else if (type == "warning") {
        return "#d60000";
      } else if (type == "confirmation") {
        return "info";
      }
    },
  },
  methods: {
    resetState() {
      this.dialog = {
        checkboxLabel: "",
        checkbox: false,
        title: "",
        message: "",
        button: {},
      };
    },
    handleClickButton(confirm) {
      this.isShow = false;
      // callback
      if (this.params.callback) {
        this.params.callback(confirm, this.dialog.checkbox);
      }
    },
    open(params) {
      this.resetState();
      this.params = params;
      this.isShow = true;
      // set params to dialog state
      Object.entries(params).forEach((param) => {
        if (typeof param[1] == typeof this.dialog[param[0]]) {
          this.dialog[param[0]] = param[1];
        }
      });
    },
  },
  mounted() {
    if (!document) return;
    events.$on("open", this.open);
  },
};

export default Component;
</script>

<style lang="scss" scoped>
.card {
  border-radius: 1.3rem !important;

  .dialog-title-icon {
    font-size: 23px !important;
  }
}

h3 {
  font-family: $primary-font;
  font-size: 14px;
}

p {
  font-family: $secondary-font;
  font-size: 13px;
}

.checkbox {
  ::v-deep(.v-label) {
    font-family: $secondary-font;
    font-size: 13px;
  }
}

.button {
  width: max-content;
  padding: 0px 20px !important;
  text-transform: capitalize !important;
  letter-spacing: 0 !important;
  font-family: $secondary-font;
  font-weight: $font-semibold;
  font-size: 12px !important;
  transition: 0.3s;

  &:focus {
    outline: 0;
  }
}
</style>
