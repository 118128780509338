<template>
  <v-list-item :to="to">
    <v-list-item-icon>
      <v-img class="icon-image" width="24" height="24" :src="src" />
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title>{{ title }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "ListGroup",
  props: ["to", "title", "src"],
};
</script>

<style lang="scss" scoped>
.v-list-item__icon {
  margin-right: 14px !important;
  margin-left: 14px !important;
  margin-top: auto;
  margin-bottom: auto;
}

.v-list-item__title {
  font-family: $secondary-font;
  text-transform: capitalize;
  font-size: 12px;
}

.v-list-item {
  transition: all 0.5s ease 0s;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  height: 56px !important;

  .icon-image {
    filter: brightness(100);
  }

  &--active {
    background-color: var(--v-accent-base) !important;
    color: var(--v-primary-base) !important;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;

    .icon-image {
      filter: none;
    }
  }

  &--link:before {
    background: none;
  }

  &:hover {
    transform: translate(0px, -5px);
  }
}
</style>
