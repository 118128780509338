<template>
  <v-app-bar id="appbar" color="accent" height="76px" elevation="0" app>
    <div class="main ml-3">
      <h5>{{ getCurrentRoute }}</h5>
    </div>
  </v-app-bar>
</template>

<script>
import router from "@/router";

export default {
  name: "appbar-regular",
  data() {
    return {};
  },
  computed: {
    getCurrentRoute() {
      return this.$route.name;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.menu {
  border: 1px solid #e0e0e0;
}

.main {
  gap: 1rem;

  h5 {
    font-family: Comfortaa, cursive;
    font-size: 18px !important;
    font-weight: $font-black;
    text-transform: capitalize;
  }
}

#appbar {
  z-index: 100;

  .wrapper {
    position: relative;
    border-radius: 1rem;
    background-color: rgba(212, 212, 212, 0.17);
    margin-left: 0px;

    .search {
      background-color: rgb(227, 231, 234);
      border-radius: inherit;
      padding: 0px 12px;
      height: 100%;
      position: absolute;
      pointer-events: none;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
    }

    .field {
      border-radius: inherit;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.4375em;
      letter-spacing: 0.00938em;
      box-sizing: border-box;
      position: relative;
      cursor: text;
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      color: inherit;
      padding-left: 2rem;
      width: 12rem;

      transition: all 0.2s;
    }
    .v-input--is-focused {
      width: 16rem !important;
    }
  }

  .wrapper-avatar {
    p {
      text-transform: capitalize;
    }
  }
}
</style>
