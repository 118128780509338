<template>
  <v-list dark>
    <v-list-item-group>
      <v-list-item :ripple="false" class="px-0 my-1" link :to="to">
        <template v-slot:default="{ active }" v-if="icon && activeIcon">
          <v-list-item-icon>
            <v-icon style="font-size: 20px !important">{{
              active ? activeIcon : icon
            }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ title }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <div class="d-flex" v-else-if="icon && !activeIcon">
          <v-list-item-icon>
            <v-icon style="font-size: 20px !important">{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ title }}</v-list-item-title>
          </v-list-item-content>
        </div>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "ListNogroup",
  props: ["icon", "activeIcon", "to", "title"],
};
</script>

<style lang="scss" scoped>
.v-list {
  .v-list-item__icon {
    margin-right: 14px !important;
    margin-left: 14px !important;
    justify-content: center;
  }

  .v-list-item__title {
    font-family: $secondary-font;
    text-transform: capitalize;
    font-size: 12px;
  }

  .v-list-item {
    transition: all 0.5s ease 0s;
    border-radius: 3rem;

    &--link:before {
      background: none;
    }

    &--active {
      background-color: rgba(255, 255, 255, 0.15);
      color: white;
      border-radius: 30px;
      padding: 7px 0 !important;
    }

    &:hover {
      transform: translate(0px, -5px);
      transition: all 0.5s ease 0s;
    }
  }
}
</style>
