import axios from "axios";

export default {
  namespaced: true,
  state: {
    categoryData: {
      series1: [],
      series2: [],
      loading: false,
    },
  },
  actions: {
    async categoryAsset({ state }) {
      state.categoryData = {
        ...state.categoryData,
        loading: true,
      };

      try {
        await axios({
          method: "GET",
          url: "/eb-api/eb-asset/category-asset",
        }).then((res) => {
          let resp = res.data.data;

          const groupedData = resp.reduce((acc, current) => {
            if (acc[current.category]) {
              acc[current.category] += current.count;
            } else {
              acc[current.category] = current.count;
            }
            return acc;
          }, {});
          const finalData = Object.entries(groupedData).map(([name, value]) => {
            return { name, value: [value] };
          });
          state.categoryData = {
            ...state.categoryData,
            series1: finalData,
            loading: false,
          };

          let result = [];
          resp.map((element) => {
            result.push({
              name: element.sub_category,
              value: element.count,
            });
          });

          state.categoryData.series2 = result;
        });
      } catch (error) {
        state.categoryData = {
          ...state.categoryData,
          loading: false,
        };
      }
    },
  },
};
