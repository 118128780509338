<template>
  <BaseCard class="card-login mx-auto mt-2 mb-0" width="480px">
    <p class="content-title mb-2">Sign in to your account</p>
    <hr class="mb-4" />

    <v-alert v-if="loginError?.status" class="alert" color="#ffe9e9" dismissible close-icon="mdi-close" elevation="0">
      {{ loginError.text }}
    </v-alert>

    <v-form class="card-loginform" ref="form">
      <BaseTextField class="d-flex my-3" height="45" outlined hide-details="auto" v-model="form.username"
        :rules="[(v) => !!v || 'username is required']" @keydown.enter="handleLogin">
        <template>
          <p>Username <b class="red--text">*</b></p>
        </template>
      </BaseTextField>

      <BaseTextField class="d-flex my-3 mt-5" hide-details="auto" height="45" outlined v-model="form.password"
        :type="showPassword ? 'text' : 'password'" :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off'"
        :rules="[(v) => !!v || 'username is required']" @click:append="showPassword = !showPassword"
        @keydown.enter="handleLogin">
        <template>
          <p>Password <b class="red--text">*</b></p>
        </template>
      </BaseTextField>

      <div class="d-flex">
        <!-- <BaseCheckbox
          label="Remember Me"
          v-model="form.savePassword"
          :ripple="false"
        /> -->
        <a class="d-flex align-self-center ml-auto" href="https://t.me/Binstro_bot" target="_blank">
          <p>Forgot Password?</p>
        </a>
      </div>

      <BaseButton class="mt-4" title="Login" color="primary" depressed rounded block @click="handleLogin" />
    </v-form>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/base/card/base-card.vue";
import BaseTextField from "@/components/base/input/base-text-field.vue";
import BaseCheckbox from "@/components/base/input/base-checkbox.vue";
import BaseButton from "@/components/base/button/base-button.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      showPassword: false,
      form: {
        username: "",
        password: "",
        savePassword: false,
      },
    };
  },
  components: {
    BaseCard,
    BaseTextField,
    BaseCheckbox,
    BaseButton,
  },
  computed: {
    ...mapState("gatewayLogin", ["loginError"]),
  },
  methods: {
    handleLogin() {
      this.$store.dispatch("gatewayLogin/handleLogin", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-login {
  z-index: 2;
  background-color: white;
  padding: 2.5rem;
  border-radius: 1.5rem !important;

  .alert {
    border-radius: 18px !important;

    ::v-deep(.v-icon) {
      font-size: 20px;
    }
  }

  .content-title {
    font-family: $secondary-font;
    font-size: $sizing-standard !important;
    font-weight: $font-regular;
    color: black;
    line-height: 1.5;
  }

  hr {
    width: 20%;
    border: none;
    height: 0.2rem;
    border-radius: 2rem;
    background-color: var(--v-primary-base);
  }
}
</style>
