<template>
  <DialogCard v-model="showCreateDialog">
    <template #title> Update Asset </template>

    <v-stepper elevation="0" v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1">
          Asset Info
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 2" step="2">
          Asset Detail
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3"> Asset Attachment </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-form ref="e1" v-model="stepValid.e1">
            <div class="d-flex flex-column gap-6">
              <!-- step 1 : info -->
              <div class="d-flex flex-column gap-2">
                <p class="font-4">Asset Info</p>

                <div class="d-flex flex-column gap-3">
                  <BaseSelect
                    label="Type"
                    item-text="name"
                    item-value="value"
                    height="45"
                    outlined
                    :rules="[(v) => !!v || 'Required']"
                    :items="typeItems"
                    v-model="form.type"
                  >
                    <p class="font-3">Type <b class="red--text">*</b></p>
                  </BaseSelect>

                  <v-row>
                    <v-col cols="12" sm="4">
                      <BaseSelect
                        label="Type"
                        item-text="name"
                        item-value="value"
                        height="45"
                        outlined
                        :rules="[(v) => !!v || 'Required']"
                        :items="categoryItems"
                        v-model="form.category"
                      >
                        <p class="font-3">
                          Category <b class="red--text">*</b>
                        </p>
                      </BaseSelect>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <BaseSelect
                        label="Type"
                        item-text="name"
                        item-value="name"
                        height="45"
                        outlined
                        :rules="[(v) => !!v || 'Required']"
                        :items="listItems.list_sub_category"
                        @change="setSubCategory"
                        v-model="form.sub_category"
                      >
                        <p class="font-3">
                          Sub Category <b class="red--text">*</b>
                        </p>
                      </BaseSelect>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <BaseTextField
                        label="Type"
                        height="45"
                        outlined
                        disabled
                        :rules="[(v) => !!v || 'Required']"
                        v-model="form.sub_category_code"
                      >
                        <p class="font-3">
                          Sub Category Code <b class="red--text">*</b>
                        </p>
                      </BaseTextField>
                    </v-col>
                  </v-row>

                  <BaseTextField
                    height="45"
                    outlined
                    type="number"
                    :rules="[(v) => !!v || 'Required']"
                    v-model="form.number_code"
                  >
                    <p class="font-3">Number Code <b class="red--text">*</b></p>
                  </BaseTextField>
                </div>
              </div>

              <!-- step 1 : description -->
              <div class="d-flex flex-column gap-2">
                <p class="font-4">Asset Description</p>

                <div class="d-flex flex-column gap-3">
                  <BaseDatepicker
                    item-text="name"
                    item-value="value"
                    :rules="[(v) => !!v || 'Required']"
                    :items="categoryItems"
                    v-model="form.purchase_date"
                  >
                    <p class="font-3">
                      Purchase Date <b class="red--text">*</b>
                    </p>
                  </BaseDatepicker>

                  <BaseTextField
                    height="45"
                    outlined
                    type="number"
                    prefix="Rp."
                    :rules="[(v) => !!v || 'Required']"
                    v-model="form.price"
                  >
                    <p class="font-3">Price <b class="red--text">*</b></p>
                  </BaseTextField>

                  <v-row>
                    <v-col cols="12" sm="4">
                      <BaseSelect
                        item-text="name"
                        item-value="code"
                        :items="listItems.list_company"
                        height="45"
                        outlined
                        :rules="[(v) => !!v || 'Required']"
                        v-model="form.company"
                      >
                        <p class="font-3">Company <b class="red--text">*</b></p>
                      </BaseSelect>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <BaseTextField
                        v-if="form.type == 'type1'"
                        height="45"
                        type="text"
                        outlined
                        :rules="[(v) => !!v || 'Required']"
                        v-model="form.ip_address"
                      >
                        <p class="font-3">
                          IP Address <b class="red--text">*</b>
                        </p>
                      </BaseTextField>

                      <BaseAutocomplete
                        v-else
                        item-text="name"
                        item-value="name"
                        :loading="employeeData.status"
                        :items="employeeItems"
                        :rules="[(v) => !!v || 'Required']"
                        @update:search-input="search = $event"
                        v-model="form.holder"
                      >
                        <p class="font-3">Holder <b class="red--text">*</b></p>
                      </BaseAutocomplete>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <BaseSelect
                        item-text="name"
                        item-value="name"
                        height="45"
                        outlined
                        :rules="[(v) => !!v || 'Required']"
                        :items="listItems.list_location"
                        v-model="form.location"
                      >
                        <p class="font-3">
                          Location <b class="red--text">*</b>
                        </p>
                      </BaseSelect>
                    </v-col>
                  </v-row>

                  <div v-if="!!form.type">
                    <v-row class="mt-0" v-if="form.type == 'type1'">
                      <v-col class="pt-0" cols="12" sm="4">
                        <BaseTextField
                          height="45"
                          outlined
                          v-model="form.room"
                          :rules="[(v) => !!v || 'Required']"
                        >
                          <p class="font-3">Room <b class="red--text">*</b></p>
                        </BaseTextField>
                      </v-col>
                      <v-col class="pt-0" cols="12" sm="4">
                        <BaseTextField
                          height="45"
                          outlined
                          v-model="form.rack"
                          :rules="[(v) => !!v || 'Required']"
                        >
                          <p class="font-3">Rack <b class="red--text">*</b></p>
                        </BaseTextField>
                      </v-col>
                      <v-col class="pt-0" cols="12" sm="4">
                        <BaseSelect
                          item-text="name"
                          item-value="value"
                          height="45"
                          outlined
                          :rules="[(v) => !!v || 'Required']"
                          :items="statusItems"
                          v-model="form.status"
                        >
                          <p class="font-3">
                            Status <b class="red--text">*</b>
                          </p>
                        </BaseSelect>
                      </v-col>
                    </v-row>

                    <div v-else-if="form.type == 'type2'">
                      <BaseSelect
                        item-text="name"
                        item-value="value"
                        height="45"
                        outlined
                        :rules="[(v) => !!v || 'Required']"
                        :items="statusItems"
                        v-model="form.status"
                      >
                        <p class="font-3">Status <b class="red--text">*</b></p>
                      </BaseSelect>
                    </div>
                  </div>

                  <BaseTextField
                    height="45"
                    outlined
                    :rules="[(v) => !!v || 'Required']"
                    v-model="form.description"
                  >
                    <p class="font-3">Description <b class="red--text">*</b></p>
                  </BaseTextField>
                </div>
              </div>

              <!-- Stepper Action -->
              <div class="d-flex justify-end gap-3">
                <BaseButton
                  title="Continue"
                  color="primary"
                  height="40px"
                  depressed
                  rounded
                  :disabled="!stepValid.e1"
                  @click="e1 = 2"
                />
                <BaseButton
                  title="Cancel"
                  height="40px"
                  depressed
                  rounded
                  @click="showCreateDialog = false"
                />
              </div>
            </div>
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="2">
          <div class="d-flex flex-column gap-6">
            <!-- step 2 : Asset Detail -->
            <div class="d-flex flex-column gap-2">
              <p class="font-4">Asset Detail</p>

              <v-row>
                <v-col>
                  <div class="d-flex flex-column gap-3">
                    <BaseSelect
                      item-text="name"
                      item-value="name"
                      height="45"
                      outlined
                      :rules="[(v) => !!v || 'Required']"
                      :items="listItems.list_brand"
                      v-model="seriesDetail.brand"
                    >
                      <p class="font-3">Brand <b class="red--text">*</b></p>
                    </BaseSelect>
                    <BaseTextField
                      height="45"
                      outlined
                      :rules="[(v) => !!v || 'Required']"
                      v-model="seriesDetail.series"
                    >
                      <p class="font-3">Series <b class="red--text">*</b></p>
                    </BaseTextField>
                    <BaseSelect
                      item-text="name"
                      item-value="value"
                      height="45"
                      outlined
                      :rules="[(v) => !!v || 'Required']"
                      :items="[
                        { name: 'Inactive', value: 'inactive' },
                        { name: 'Active', value: 'active' },
                      ]"
                      v-model="seriesDetail.status"
                    >
                      <p class="font-3">Status <b class="red--text">*</b></p>
                    </BaseSelect>
                    <BaseTextField
                      height="45"
                      outlined
                      :rules="[(v) => !!v || 'Required']"
                      v-model="seriesDetail.serial_number"
                    >
                      <p class="font-3">
                        Serial Number <b class="red--text">*</b>
                      </p>
                    </BaseTextField>

                    <!-- add series -->
                    <BaseButton
                      class="ml-auto"
                      title="Add Series"
                      icon-append="iconoir-plus"
                      height="40px"
                      depressed
                      rounded
                      :rules="[(v) => !!v || 'Required']"
                      @click="addSeries('add')"
                    />
                  </div>
                </v-col>
                <v-col>
                  <v-row>
                    <v-col
                      v-for="(item, index) of form.asset"
                      :key="index"
                      cols="12"
                      sm="4"
                    >
                      <BaseCard
                        class="d-flex flex-column justify-space-between"
                        :height="'100%'"
                        outlined
                      >
                        <template #title>
                          <i
                            class="iconoir-trash"
                            @click="addSeries('delete', index)"
                          ></i>
                        </template>
                        <v-container>
                          <p>{{ item.brand }}</p>
                          <p>{{ item.series }}</p>
                          <p>{{ item.status }}</p>
                          <p style="text-transform: capitalize">
                            {{ item.serial_number }}
                          </p>
                        </v-container>
                      </BaseCard>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>

            <!-- Stepper Action -->
            <div class="d-flex gap-3">
              <BaseButton
                title="Back"
                height="40px"
                depressed
                rounded
                @click="e1 = e1 - 1"
              />
              <BaseButton
                class="ml-auto"
                title="Continue"
                color="primary"
                height="40px"
                depressed
                rounded
                :disabled="form.asset.length < 1"
                @click="e1 = 3"
              />
              <BaseButton
                title="Cancel"
                height="40px"
                depressed
                rounded
                @click="showCreateDialog = false"
              />
            </div>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <div class="d-flex flex-column gap-6">
            <!-- step 3 : Asset Attachment -->
            <div class="d-flex flex-column gap-2">
              <p class="font-4">Asset Attachment</p>

              <v-row>
                <v-col>
                  <v-file-input
                    class="custom-base-input"
                    counter
                    multiple
                    outlined
                    placeholder="Insert Image"
                    prepend-inner-icon="iconoir-camera"
                    prepend-icon=""
                    height="45px"
                    accept="image/*"
                    v-model="formImage.image"
                  ></v-file-input>
                </v-col>
                <v-col>
                  <v-file-input
                    class="custom-base-input"
                    counter
                    multiple
                    outlined
                    placeholder="Insert Invoice"
                    prepend-inner-icon="iconoir-camera"
                    prepend-icon=""
                    height="45px"
                    accept="image/*"
                    v-model="formImage.invoice"
                  ></v-file-input>
                </v-col>
              </v-row>
            </div>

            <!-- Stepper Action -->
            <div class="d-flex gap-3">
              <BaseButton
                title="Back"
                height="40px"
                depressed
                rounded
                @click="e1 = e1 - 1"
              />
              <BaseButton
                class="ml-auto"
                title="Submit"
                color="primary"
                height="40px"
                depressed
                rounded
                @click="submitData()"
              />
              <BaseButton
                title="Cancel"
                height="40px"
                depressed
                rounded
                @click="showCreateDialog = false"
              />
            </div>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </DialogCard>
</template>

<script>
import DialogCard from "@/components/base/dialog/dialog-card.vue";
import BaseSelect from "@/components/base/input/base-select.vue";
import BaseTextField from "@/components/base/input/base-text-field.vue";
import BaseDatepicker from "@/components/base/input/base-datepicker.vue";
import BaseButton from "@/components/base/button/base-button.vue";
import BaseCard from "@/components/base/card/base-card.vue";
import BaseAutocomplete from "@/components/base/input/base-autocomplete.vue";
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "create-asset-dialog",
  data() {
    return {
      search: undefined,
      e1: 1,
      stepValid: {
        e1: false,
        e2: false,
        e3: false,
      },
      typeItems: [
        { name: "Server", value: "type1" },
        { name: "Karyawan", value: "type2" },
      ],
      categoryItems: [
        { name: "Hardware", value: "Hardware" },
        { name: "Software", value: "Software" },
      ],
      companyItems: [
        { name: "Bintaro", value: "Hardware" },
        { name: "PH", value: "Software" },
      ],
      statusItems: [
        { name: "Tersedia", value: "tersedia" },
        { name: "Dipakai", value: "dipakai" },
        { name: "Rusak", value: "rusak" },
        { name: "Diperbaiki", value: "diperbaiki" },
      ],
      listItems: {
        list_brand: [],
        list_sub_category: [],
        list_category: [],
        list_company: [],
        list_location: [],
      },
      seriesDetail: {
        brand: "",
        series: "",
        status: "",
        serial_number: "",
      },
      form: {
        id: "",
        // asset info
        type: "",
        category: "",
        sub_category: "",
        sub_category_code: "",
        number_code: "",
        holder: "",

        // asset description
        purchase_date: "",
        price: "",
        company: "",
        ip_address: "",
        location: "",
        room: "",
        rack: "",
        status: "",
        description: "",

        // asset detail
        asset: [],
      },
      formImage: {
        image: [],
        invoice: [],
      },
    };
  },
  props: ["value", "params"],
  components: {
    DialogCard,
    BaseSelect,
    BaseTextField,
    BaseDatepicker,
    BaseButton,
    BaseCard,
    BaseAutocomplete,
  },
  computed: {
    showCreateDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    employeeItems() {
      return [...this.employeeData.data, { name: "No Holder" }];
    },
    ...mapState("getEmployee", ["employeeData"]),
  },
  watch: {
    params() {
      console.log(this.params);
      Object.keys(this.form).map((key) => {
        this.form[key] = this.params[key];
      });

      Object.keys(this.formImage).map((key) => {
        let i = 1;
        if (this.params[key].length == 0) return;
        this.formImage[key].push(
          this.getFileFromBase64(this.params[key], `${key}-${i}`)
        );
        i = i++;
      });

      this.form.type == "type2"
        ? this.$store.dispatch("getEmployee/getEmployee", this.form.holder)
        : false;

      // Object.keys(this.formImage).map((key) => {
      //   let i = 1;
      //   if (this.params[key].length == 0) return;
      //   this.formImage[key].push(
      //     this.getFileFromBase64(this.params[key], `${key}-${i}`)
      //   );
      //   i = i++;
      // });
    },
    search(newVal, oldVal) {
      if (this.search?.length >= 3 && this.form.holder !== "No Holder") {
        this.$store.dispatch("getEmployee/getEmployee", this.search);
      }
    },
  },
  created() {
    this.getClassificationData();
  },
  methods: {
    getFileFromBase64(stringbase64, fileName) {
      const imageContent = atob(stringbase64, "base64");
      const buffer = new ArrayBuffer(imageContent.length);
      const view = new Uint8Array(buffer);

      for (let n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n);
      }
      const type = "image/jpeg";
      const blob = new Blob([buffer], { type });
      return new File([blob], fileName, {
        lastModified: new Date().getTime(),
        type,
      });
    },
    setSubCategory(e) {
      let data = this.listItems.list_sub_category.find((i) => i.name == e);
      this.form.sub_category = data.name;
      this.form.sub_category_code = data.code;
    },
    addSeries(e1, e2) {
      let data = {
        brand: this.seriesDetail.brand,
        series: this.seriesDetail.series,
        status: this.seriesDetail.status,
        serial_number: this.seriesDetail.serial_number,
      };

      if (e1 == "add") {
        this.form.asset.push(data);
      } else {
        this.form.asset.splice(e2, 1);
      }
    },
    getClassificationData() {
      axios
        .get("/eb-api/eb-asset/get-data?type=asset", {})
        .then((response) => {
          this.listItems.list_brand = response.data.data.brand;
          this.listItems.list_sub_category = response.data.data.sub_category;
          this.listItems.list_category = response.data.data.category;
          this.listItems.list_company = response.data.data.company;
          this.listItems.list_location = response.data.data.location;
          // this.tabOption.classificationTab.push(response.data.data);
          // this.tabOption.classificationTabHeader = Object.keys(
          //   response.data.data
          // );
          // this.loadtables = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async submitData() {
      let formData = new FormData();
      if (this.formImage.image.length > 0) {
        this.formImage.image.forEach((i) => {
          formData.append("image", i);
        });
      }

      if (this.formImage.invoice.length > 0) {
        this.formImage.invoice.forEach((i) => {
          formData.append("invoice", i);
        });
      }

      if (this.form.type == "type1") {
        this.form.holder = "unknown";
      } else {
        this.form.ip_address = "unknown";
      }
      formData.append(
        "updateAsset",
        JSON.stringify({ ...this.form, number: this.params.number })
      );

      await axios({
        method: "PUT",
        url: "/eb-api/eb-asset/update-asset",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          this.showCreateDialog = false;
          this.$alert({
            type: "success",
            title: "Success",
            message: "Success update asset",
            button: {
              yes: "Okay",
            },
            callback: () => {
              Object.keys(this.formImage).forEach((key) => {
                this.formImage[key] = [];
              });
              this.$emit("onsuccess");
            },
          });
        })
        .catch((error) => {
          this.$alert({
            type: "warning",
            title: "Warning",
            message: "Failed to update asset",
            button: {
              yes: "Okay",
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-base-input ::v-deep(.v-input__control) .v-input__slot {
  border-radius: 18px !important;
  min-height: v-bind(height) !important;
  display: flex !important;
  align-items: center !important;

  .v-label {
    font-size: 14px;
    top: auto !important;
    bottom: auto !important;

    &--active {
      transform: translateY(-23px) scale(0.75) !important;
    }
  }

  .v-input__prepend-inner {
    margin-top: auto;
    margin-bottom: auto;

    i {
      font-size: 18px;
    }
  }

  .v-input__append-inner {
    margin-top: auto !important;
    margin-bottom: auto !important;

    .v-icon {
      font-size: 18px !important;
    }
  }
}
</style>
