<template>
  <VChart
    :option="chartOpt"
    :loading="loading"
    autoresize
    style="height: 90%"
    v-on="$listeners"
  />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([CanvasRenderer, PieChart, BarChart, TooltipComponent, LegendComponent]);

export default {
  name: "base-echart",
  components: {
    VChart,
  },
  props: ["chartOpt", "height", "loading"],
  computed: {
    option() {
      return this.chartOpt;
    },
  },
};
</script>

<style></style>
