<template>
  <v-card class="card" elevation="0">
    <v-layout align-center justify-end>
      <v-img class="image__side" src="@/assets/image/bubble.svg"></v-img>
      <v-flex>
        <p class="title">{{ title }}</p>
        <p class="value">{{ value }}</p>
        <p class="description">{{ description }}</p>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: "InfoCard",
  props: ["title", "value", "description"],
};
</script>

<style lang="scss" scoped>
.card {
  background: linear-gradient(224deg, rgb(6 86 240), rgb(69, 211, 255));
  border-radius: 1rem !important;
  font-family: $primary-font;
  transition: all 0.5s ease 0s;
  color: white;
  position: relative;

  &:hover {
    cursor: pointer;
    transition: 0.5s all !important;
    transform: translate(0px, -5px);
  }

  .image__side {
    opacity: 0.6;
    position: absolute;
    max-width: 85%;
  }

  .layout {
    color: white;
    height: 100%;

    .flex {
      padding: .75rem 1.5rem;

      .title {
        font-family: $primary-font !important;
        font-size: 14px !important;
        text-transform: capitalize;

        @include mobile {
          font-size: 10px !important;
        }
      }

      .value {
        font-family: $primary-font !important;
        font-size: 30px !important;
        text-transform: capitalize;
        text-align: center !important;

        @include mobile {
          font-size: 20px !important;
        }
      }
      .description {
        text-transform: capitalize;
        text-align: center;

        @include mobile {
          font-size: 9px !important;
          font-weight: $font-semibold;
        }
      }
    }
  }
}
</style>
