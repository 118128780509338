<template>
  <v-btn class="custom-button" :style="{ color: textColor, backgroundColor: color }" :absolute="absolute" :block="block"
    :color="color" :dark="dark" :depressed="depressed" :disabled="disabled" :fixed="fixed" :height="height"
    :large="large" :outlined="outlined" :plain="plain" :ripple="ripple" :rounded="rounded" :small="small" :to="to"
    :text="text" :width="width" v-on="$listeners">
    <v-icon class="mr-2" v-if="iconPrepend">{{ iconPrepend }}</v-icon>
    {{ title }}
    <v-icon class="ml-2" v-if="iconAppend">{{ iconAppend }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "BaseButton",
  props: [
    "absolute",
    "block",
    "color",
    "dark",
    "depressed",
    "disabled",
    "fixed",
    "height",
    "large",
    "outlined",
    "plain",
    "ripple",
    "small",
    "to",
    "text",
    "width",
    "title",
    "iconPrepend",
    "iconAppend",
    "textColor",
    "color",
    "rounded",
  ],
};
</script>

<style lang="scss" scoped>
.custom-button {
  width: max-content;
  padding: 0px 20px !important;
  text-transform: capitalize !important;
  letter-spacing: 0 !important;
  font-family: $secondary-font;
  font-size: 12px !important;
  transition: 0.3s;
  margin: 0px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;

  .v-icon {
    font-size: 15px !important;
  }

  &:focus {
    outline: 0;
  }

  &::before {
    background: none !important;
  }
}
</style>
