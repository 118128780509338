<template>
  <div class="text-divider">{{ title }}</div>
</template>

<script>
export default {
  name: "text-divider",
  props: ["title"],
};
</script>

<style lang="scss" scoped>
.text {
  font-family: $secondary-font !important;
  font-size: 12px !important;
  text-transform: capitalize;
  white-space: nowrap;
}

body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding: 1em;
}

.text-divider {
  display: flex;
  align-items: center;
  font-family: $secondary-font !important;
  font-size: 12px !important;
  text-transform: capitalize;
  white-space: nowrap;
  --text-divider-gap: 1rem;

  &::before,
  &::after {
    content: "";
    height: 1px;
    background-color: #9e9e9e;
    flex-grow: 1;
  }

  &::before {
    margin-right: var(--text-divider-gap);
  }

  &::after {
    margin-left: var(--text-divider-gap);
  }
}
</style>
