import axios from "axios";

export default {
  namespaced: true,
  state: {
    serverTableData: {
      data: [],
      loading: false,
    },
  },
  actions: {
    async serverTableAsset({ state }) {
      state.serverTableData = {
        ...state.serverTableData,
        loading: true,
      };

      try {
        await axios({
          method: "GET",
          url: "/eb-api/eb-asset/server-asset",
        }).then((res) => {
          let resp = res.data.data;

          let result = [];
          resp.map((a) =>
            result.push({
              ip_address: a.ip_address,
              asset: a.asset,
              total: a.count,
            })
          );

          state.serverTableData.data = result;
        });

        state.serverTableData = {
          ...state.serverTableData,
          loading: false,
        };
      } catch (error) {
        state.serverTableData = {
          ...state.serverTableData,
          loading: false,
        };
      }
    },
  },
};
