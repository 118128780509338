<template>
  <div id="root">
    <AddItemDialog @onsuccess="onAddSuccess()" v-model="showAddDialog" />
    <AddClassification v-model="showClassificationDialog" />
    <DetailDialogAtk v-model="showDetail.show" :params="showDetail.params" />
    <EditItemDialog
      v-model="showUpdate"
      :params="editParams"
      @onsuccess="onAddSuccess()"
    />

    <div class="d-flex flex-column gap-4">
      <div>
        <v-row>
          <v-col>
            <BaseTextField
              append-icon="iconoir-search"
              height="20px"
              placeholder="Search"
              outlined
              v-model="getAtkParam.search"
              @input="getAtkData()"
            ></BaseTextField>
          </v-col>

          <v-col
            class="d-flex flex-column flex-md-row gap-4 align-md-center justify-end"
          >
            <div class="d-flex gap-2">
              <BaseButton
                class="ml-lg-auto"
                title="Add Classification"
                icon-append="iconoir-plus"
                color="primary"
                height="40px"
                depressed
                rounded
                @click="showClassificationDialog = !showClassificationDialog"
              />
              <BaseButton
                class="ml-auto"
                title="Add New Item"
                icon-append="iconoir-plus"
                color="primary"
                height="40px"
                depressed
                rounded
                @click="showAddDialog = !showAddDialog"
              />
            </div>
            <BaseButtonIcon
              color="primary"
              icon="iconoir-settings"
              depressed
              dark
              outlined
              @click="expand = !expand"
            />
          </v-col>
        </v-row>

        <v-expand-transition>
          <v-row class="mt-0" v-show="expand">
            <v-col cols="12" sm="2">
              <BaseSelect
                append-icon="iconoir-search"
                height="20px"
                placeholder="Search"
                outlined
                item-text="name"
                item-value="value"
                :items="[
                  { name: 'All', value: '*' },
                  { name: 'Medicine', value: 'medicine' },
                  { name: 'ATK', value: 'atk' },
                ]"
                v-model="getAtkParam.type"
                @change="getAtkData"
              ></BaseSelect>
            </v-col>
          </v-row>
        </v-expand-transition>
      </div>

      <TableData
        hide-pagination
        :height="'100%'"
        :items="stationaryDataTable.list_data"
        :headers="headerAssets"
        :itemsPerPageOptions="[5, 10, 20, 100, -1]"
        @click:row="detailDialog($event)"
        @update:items-per-page="
          getAtkParam.size = $event;
          getAtkData();
        "
      >
        <template v-slot:[`item.action`]="{ item }">
          <BaseButtonIcon
            icon="iconoir-trash"
            color="red"
            depressed
            @click.stop="handleDeleteItem(item)"
          />
          <BaseButtonIcon
            icon="iconoir-edit-pencil"
            color="blue"
            @click.stop="
              showUpdate = !showUpdate;
              editParams = item;
            "
          />
        </template>

        <template v-slot:[`item.price`]="{ item }">
          {{ item.price | toCurrency }}
        </template>

        <template v-slot:[`item.type`]="{ item }">
          {{ item.type | uppercase }}
        </template>

        <template v-slot:[`item.purchase_date`]="{ item }">
          {{ item.purchase_date | moment("MMMM DD, YYYY") }}
        </template>

        <template #pagination>
          <v-pagination
            color="primary"
            :total-visible="7"
            :length="paginationLength || 1"
            v-model="getAtkParam.page"
            @input="getAtkData()"
          ></v-pagination>
        </template>
      </TableData>
    </div>
  </div>
</template>

<script>
import TableData from "@/components/base/table/table-data.vue";
import BaseTextField from "@/components/base/input/base-text-field.vue";
import BaseButton from "@/components/base/button/base-button.vue";
import BaseButtonIcon from "@/components/base/button/base-button-icon.vue";
import AddItemDialog from "./component/add-item-dialog.vue";
import AddClassification from "./component/add-classification.vue";
import DetailDialogAtk from "./component/detail-dialog-atk.vue";
import EditItemDialog from "./component/edit-item-dialog.vue";
import BaseSelect from "@/components/base/input/base-select.vue";
import axios from "axios";
import { mapState } from "vuex";

export default {
  data() {
    return {
      expand: false,
      showDetail: {
        show: false,
        params: {},
      },
      editParams: {},
      showUpdate: false,
      showAddDialog: false,
      showClassificationDialog: false,
      getAtkParam: {
        page: 1,
        size: 5,
        search: undefined,
        type: "*",
      },
      headerAssets: [
        {
          text: "Category",
          value: "category",
          align: "left",
          width: "12.5%",
        },
        {
          text: "Sub Category",
          value: "sub_category",
          align: "left",
          width: "12.5%",
        },
        {
          text: "Name",
          value: "name",
          align: "left",
          width: "12.5%",
        },
        {
          text: "Price",
          value: "price",
          align: "left",
          width: "12.5%",
        },
        {
          text: "Quantity",
          value: "qty",
          align: "left",
          width: "12.5%",
        },
        {
          text: "Type",
          value: "type",
          align: "left",
          width: "12.5%",
        },
        {
          text: "Purchase Date",
          value: "purchase_date",
          align: "left",
          width: "12.5%",
        },
        {
          text: "Action",
          value: "action",
          align: "center",
          width: "12.5%",
          sortable: false,
        },
      ],
    };
  },
  components: {
    TableData,
    BaseTextField,
    BaseButton,
    BaseButtonIcon,
    BaseSelect,
    AddItemDialog,
    AddClassification,
    DetailDialogAtk,
    EditItemDialog,
  },
  created() {
    this.getAtkData();
  },
  computed: {
    ...mapState("stationaryGetAtk", ["atkData"]),
    stationaryDataTable() {
      return this.atkData.data;
    },
    paginationLength() {
      return Math.ceil(
        this.stationaryDataTable?.total / this.getAtkParam?.size
      );
    },
  },
  methods: {
    detailDialog(e) {
      console.log(e);
      this.showDetail = {
        ...this.showDetailDialog,
        show: true,
        params: e,
      };
    },
    getAtkData() {
      this.$store.dispatch("stationaryGetAtk/getAtk", this.getAtkParam);
    },
    async onAddSuccess() {
      setTimeout(() => {
        this.getAtkData();
      }, 1000);
    },
    async handleDeleteItem(e) {
      this.$alert({
        type: "warning",
        title: "Warning",
        message: `Are you sure to delete ${e.name} ?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (yes) => {
          axios({
            method: "DELETE",
            url: "/eb-api/eb-asset/delete-atk",
            params: {
              id: e.id,
            },
          }).then((res) => {
            setTimeout(() => {
              this.$alert({
                type: "success",
                title: "Success",
                message: "Success delete item",
                button: {
                  yes: "Okay",
                },
                callback: () => {
                  this.getAtkData();
                },
              });
            }, 600);
          });
        },
      });
    },
  },
};
</script>

<style></style>
