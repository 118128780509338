import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueECharts from "vue-echarts";
import VueExpandableImage from "vue-expandable-image";
import VueMoment from "vue-moment";
import "echarts";

import DetailAsset from "./components/fixed/detail-asset";
import DetailAssetPurchase from "./components/fixed/detail-asset-purchase";
import DetailTable from "./components/fixed/detail-table";
import AlertDialog from "@/components/core/alert";

Vue.use(VueExpandableImage);
Vue.use(VueMoment);
Vue.use(VueECharts);
Vue.use(DetailAsset, { componentName: "DetailAsset" });
Vue.use(DetailTable, { componentName: "DetailTable" });
Vue.use(DetailAssetPurchase, { componentName: "DetailAssetPurchase" });
Vue.use(AlertDialog, { componentName: "AlertDialog", prefixeName: "alert" });

Vue.filter("toCurrency", function (value) {
  // if (typeof value !== "number" && typeof value !== "string") {
  //   return value;
  // }
  var formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  });
  return formatter.format(value);
});

Vue.filter("capitalize", function (value) {
  if (!value) return value;
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("uppercase", function (value) {
  if (!value) return value;
  value = value.toString();
  return value.toUpperCase();
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
