<template>
  <div id="root">
    <ShowDetailDialog
      :params="showDetailDialog.params"
      v-model="showDetailDialog.show"
    />
    <EditAssetDialog
      v-model="showEditDialog.show"
      :params="showEditDialog.params"
      @onsuccess="getAssetData()"
    />
    <CreateAssetDialog v-model="showCreateDialog" @onsuccess="onAddSuccess" />
    <AddClasiificationDialog v-model="showAddClassificDialog" />
    <GenerateQrDialog
      v-model="showGenerateQR.show"
      :params="showGenerateQR.params"
    />

    <div class="d-flex flex-column gap-4">
      <v-row>
        <v-col>
          <BaseTextField
            append-icon="iconoir-search"
            height="20px"
            placeholder="Search"
            outlined
            v-model="getAssetParam.search"
            @input="getAssetData()"
          ></BaseTextField>
        </v-col>
        <v-col
          class="d-flex flex-column flex-md-row gap-4 align-md-center justify-end"
        >
          <div class="d-flex gap-2">
            <BaseButton
              title="Add Classification"
              icon-append="iconoir-plus"
              color="primary"
              height="40px"
              depressed
              rounded
              @click="showAddClassificDialog = !showAddClassificDialog"
            />
            <BaseButton
              class="ml-auto"
              title="Add New Asset"
              icon-append="iconoir-plus"
              color="primary"
              height="40px"
              depressed
              rounded
              @click="showCreateDialog = !showCreateDialog"
            />
          </div>
          <!-- <BaseButtonIcon icon="iconoir-settings" color="primary" outlined /> -->
          <FilterTable
            :id="'asset_table'"
            @change="
              applyFilter($event);
              getAssetData();
            "
          />
        </v-col>
      </v-row>

      <TableData
        :height="assetDataTable?.list_data?.length > 9 ? '550px' : '100%'"
        :headers="headerAssets"
        :items="assetDataTable.list_data"
        :itemsPerPageOptions="[5, 10, 20, 100, -1]"
        :loading="assetData.loading"
        hide-pagination
        @update:options.once="sortByEvent = $event"
        @update:items-per-page="
          getAssetParam.size = $event;
          getAssetData();
        "
        @click:row="detailDialog($event)"
      >
        <template v-slot:[`item.action`]="{ item }">
          <div class="d-flex flex-row">
            <BaseButtonIcon
              icon="iconoir-trash"
              color="red"
              depressed
              @click.stop="handleDelete(item)"
            />
            <BaseButtonIcon
              icon="iconoir-edit-pencil"
              color="blue"
              @click.stop="
                showEditDialog.show = true;
                showEditDialog.params = item;
              "
            />
            <BaseButtonIcon
              icon="iconoir-qr-code"
              color="teal"
              @click.stop="
                showGenerateQR.show = true;
                showGenerateQR.params = item;
              "
            />
          </div>
        </template>

        <template #pagination>
          <v-pagination
            color="primary"
            :total-visible="7"
            :length="paginationLength || 1"
            v-model="getAssetParam.page"
            @input="getAssetData()"
          ></v-pagination>
        </template>
      </TableData>
    </div>
  </div>
</template>

<script>
import TableData from "@/components/base/table/table-data.vue";
import BaseTextField from "@/components/base/input/base-text-field.vue";
import BaseButton from "@/components/base/button/base-button.vue";
import BaseButtonIcon from "@/components/base/button/base-button-icon.vue";
import CreateAssetDialog from "./component/create-asset-dialog.vue";
import AddClasiificationDialog from "./component/add-clasiification-dialog.vue";
import ShowDetailDialog from "./component/detail-dialog.vue";
import EditAssetDialog from "./component/edit-asset-dialog.vue";
import FilterTable from "@/components/base/filter/filter-table.vue";
import { mapActions, mapState } from "vuex";
import axios from "axios";
import GenerateQrDialog from "./component/generate-qr-dialog.vue";

export default {
  data() {
    return {
      showEditDialog: {
        show: false,
        params: {},
      },
      showDetailDialog: {
        show: false,
        params: {},
      },
      showGenerateQR: {
        show: false,
        params: {},
      },
      getAssetParam: {
        page: 1,
        size: 5,
        search: undefined,
        end: undefined,
        type: undefined,
        filter: undefined,
      },
      paginationValue: 0,
      sortByEvent: undefined,
      showCreateDialog: false,
      showAddClassificDialog: false,
      headerAssets: [
        {
          text: "Asset Number",
          value: "number",
          align: "left",
          width: "12.5%",
        },
        {
          text: "Asset Name",
          value: "sub_category_code",
          align: "left",
          width: "12.5%",
        },
        {
          text: "Location",
          value: "location",
          align: "left",
          width: "12.5%",
        },
        {
          text: "Asset Rack",
          value: "rack",
          align: "left",
          width: "12.5%",
        },
        {
          text: "Asset Room",
          value: "room",
          align: "left",
          width: "12.5%",
        },
        {
          text: "Status",
          value: "status",
          align: "left",
          width: "12.5%",
        },
        {
          text: "Purchase Date",
          value: "purchase_date",
          align: "left",
          width: "12.5%",
        },
        {
          text: "Action",
          value: "action",
          align: "center",
          width: "12.5%",
          sortable: false,
        },
      ],
    };
  },
  components: {
    TableData,
    BaseTextField,
    BaseButton,
    BaseButtonIcon,
    CreateAssetDialog,
    AddClasiificationDialog,
    ShowDetailDialog,
    EditAssetDialog,
    FilterTable,
    GenerateQrDialog,
  },
  watch: {
    sortByEvent(oldVal, newVal) {
      if (!!newVal) {
        if (
          this.sortByEvent.sortBy.length &&
          this.sortByEvent.sortDesc.length
        ) {
          this.getAssetData({
            sort: this.sortByEvent.sortDesc[0] == true ? "desc" : "asc",
            sort_by: this.sortByEvent.sortBy[0],
          });
        } else {
          this.getAssetData();
        }
      }
    },
  },
  created() {
    this.getAssetData();
  },
  computed: {
    ...mapState("assetGetAsset", ["assetData"]),
    assetDataTable() {
      return this.assetData.data;
    },
    paginationLength() {
      return Math.ceil(this.assetDataTable?.total / this.getAssetParam.size);
    },
  },
  methods: {
    handleDelete(e) {
      this.$alert({
        type: "warning",
        title: "Warning",
        message: `Are you sure to delete ${e.number} ?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (yes) => {
          axios({
            method: "DELETE",
            url: "/eb-api/eb-asset/delete-asset",
            params: {
              id: e.id,
            },
          }).then((res) => {
            setTimeout(() => {
              this.$alert({
                type: "success",
                title: "Success",
                message: "Success delete asset",
                button: {
                  yes: "Okay",
                },
                callback: () => {
                  this.getAssetData();
                },
              });
            }, 600);
          });
        },
      });
    },
    detailDialog(e) {
      this.showDetailDialog = {
        ...this.showDetailDialog,
        show: true,
        params: e,
      };
    },
    applyFilter(e) {
      this.getAssetParam = {
        ...this.getAssetParam,
        start: e?.start_date,
        end: e?.end_date,
        type: e?.category,
        filter: e?.sub_category,
      };
    },
    getAssetData(e) {
      this.$store.dispatch("assetGetAsset/getAsset", {
        ...this.getAssetParam,
        ...e,
      });
    },
    async onAddSuccess() {
      this.$alert({
        type: "success",
        title: "Success",
        message: "Success add asset",
        button: {
          yes: "Okay",
        },
        callback: () => {
          this.getAssetData();
        },
      });
    },
  },
};
</script>

<style></style>
