<template>
  <v-card
    :color="color"
    :width="width"
    :disabled="disabled"
    :outlined="outlined"
    :height="height"
    :min-height="minHeight"
    :min-width="minWidth"
    :elevation="elevation"
  >
    <v-card-title v-if="hasTitle">
      <slot name="title" />
    </v-card-title>

    <v-card-text>
      <slot />
    </v-card-text>

    <v-card-actions v-if="hasAction">
      <slot name="action" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "BaseCard",
  props: [
    "color",
    "width",
    "disabled",
    "outlined",
    "height",
    "minHeight",
    "minWidth",
    "elevation",
  ],
  computed: {
    hasTitle() {
      return !!this.$slots.title;
    },
    hasAction() {
      return !!this.$slots.action;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  .v-card__text {
    color: var(--v-fontPrimary-base);
    padding: 0 !important;
  }

  .v-card__actions {
    color: var(--v-fontPrimary-base);
  }
}
</style>
