import axios from "axios";
import router from '@/router';

export default {
  namespaced: true,
  state: {
    QRdata: {
      status: false,
      data: [],
    },
  },
  actions: {
    async getAssetQR({ state }, data) {
      state.QRdata = {
        ...state.QRdata,
        status: true,
        data: "",
      };

      try {
        await axios({
          method: "POST",
          url: "/eb-api/eb-asset/get-asset-by-qr",
          data: {
            id: router.currentRoute.params.assetID,
            page: 1,
            size: 100,
          },
        }).then((res) => {
          let response = res.data.data.list_data;
          state.QRdata = {
            ...state.QRdata,
            status: false,
            data: response,
          };
        });
      } catch (err) {
        state.QRdata = {
          ...state.QRdata,
          status: false,
        };
      }
    },
  },
};
