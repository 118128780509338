import { decrypt, encrypt } from "./security";

export function setAccount(data, storage) {
  const encrypted = encrypt(data);
  if (storage == "local") {
    localStorage.setItem("user", encrypted);
  } else if (storage == "session") {
    sessionStorage.setItem("user", encrypted);
  }
}

export function getAccount() {
  const user = localStorage?.getItem("user") ?? sessionStorage?.getItem("user");
  return decrypt(user);
}
