<template>
  <v-dialog
    overlay-opacity="0.5"
    content-class="elevation-0"
    scrollable
    :max-width="$vuetify.breakpoint.xs ? '100%' : '65%'"
    v-model="openDialog"
  >
    <BaseCard>
      <template #title> Detail Asset {{ events.title }} </template>
      <v-container>
        <v-container>
          <v-row>
            <v-col v-for="col of resData.data" :key="col.id" cols="12" sm="4">
              <BaseCard
                class="d-flex flex-column justify-space-between"
                :height="'100%'"
                outlined
              >
                <v-container>
                  <p>{{ col.serial_number }}</p>
                  <p>
                    {{ col.brand }}
                  </p>
                  <p style="text-transform: capitalize">
                    {{ col.series }}
                  </p>
                </v-container>

                <template #action>
                  <div
                    class="d-flex justify-space-between"
                    :style="{ width: '100%' }"
                  >
                    <p>{{ col.status }}</p>
                  </div>
                </template>
              </BaseCard>
            </v-col>
          </v-row>
        </v-container>
      </v-container>

      <template #action v-if="events.filter">
        <div
          v-if="!isNaN(paginationLength)"
          class="d-flex justify-space-between"
          style="width: 100%"
        >
          <div>
            <BaseSelect
              label="Cols per page"
              height="40"
              :items="[9, 15, 24]"
              v-model="filter.size"
              @change="openModal(events)"
            ></BaseSelect>
          </div>

          <v-pagination
            color="primary"
            :length="paginationLength"
            :total-visible="5"
            v-model="filter.page"
            @input="openModal(events)"
          ></v-pagination>
        </div>
      </template>
    </BaseCard>
  </v-dialog>
</template>

<script>
import axios from "axios";
import BaseCard from "@/components/base/card/base-card.vue";
import BaseSelect from "@/components/base/input/base-select.vue";
import Tabs from "@/components/base/tabs/Tabs.vue";

import { events } from "./index";
import moment from "moment";

export default {
  name: "detail-asset-purchase",
  data() {
    return {
      openDialog: false,
      events: {},
      resData: {
        data: [],
        loading: false,
      },
      filter: {
        page: 1,
        size: 9,
      },
    };
  },
  components: {
    BaseCard,
    BaseSelect,
    Tabs,
  },
  computed: {
    paginationLength() {
      return Math.ceil(this.resData.data.total / this.filter.size) ?? 1;
    },
  },
  mounted() {
    events.$on("open", this.openModal);
  },
  methods: {
    openModal(params) {
      this.openDialog = true;
      this.events = params;
      this.resData = { ...this.resData, data: [], loading: true };

      axios({
        method: "GET",
        url: `/eb-api/eb-asset/detail-table`,
        params: {
          ...params.params,
        },
      }).then((resp) => {
        let res = resp.data.data;
        this.resData = { ...this.resData, data: res, loading: false };
      });
    },
  },
};
</script>

<style></style>
