<template>
  <v-app>
    <Navbar />

    <v-main>
      <v-container class="pa-6" fluid>
        <v-row>
          <v-col v-for="(item, id) in QRdata.data" :key="id" cols="12" md="3">
            <div v-if="!!QRdata.data" max-width="100%" height="100%">
              <v-carousel
                cycle
                hide-delimiter-background
                hide-delimiters
                :show-arrows="false"
                v-if="item.image && item.image.length > 0"
              >
                <v-carousel-item>
                  <expandable-image
                    class="image"
                    close-on-background-click
                    v-bind:src="`data:image/jpeg;base64,${item.image[0]}`"
                  ></expandable-image>
                </v-carousel-item>
              </v-carousel>
              <v-carousel
                cycle
                hide-delimiter-background
                hide-delimiters
                :show-arrows="false"
                v-if="!item.image && item.image.length === 0"
              >
                <v-carousel-item class="image">
                  <v-img src="https://placehold.co/400x600"></v-img>
                </v-carousel-item>
              </v-carousel>
            </div>
          </v-col>
          <!-- Detail -->
          <v-col v-for="item in QRdata.data" cols="12" sm="4" md="4">
            <v-card
              v-if="!!QRdata.data"
              class="mb-1"
              max-width="100%"
              height="50%"
              outlined
            >
              <v-list-item v-for="asset in item.asset" three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">Asset</div>
                  <v-list-item-subtitle class="mb-1"
                    >Brand: {{ asset.brand }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle class="mb-1"
                    >Series: {{ asset.series }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle class="mb-1"
                    >Serial Num: {{ asset.serial_number }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle class="mb-1"
                    >Status: {{ asset.status }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <v-card v-if="!!QRdata.data" max-width="100%" height="50%" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">Asset Type</div>
                  <v-list-item-subtitle class="mb-1">
                    Type: {{ item.type }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="mb-1">
                    Status: {{ item.status }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="mb-1">
                    Sub Category: {{ item.category }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="mb-1">
                    Sub Cat. Code: {{ item.sub_category_code }}
                  </v-list-item-subtitle>
                  <!-- Tambahan properti lainnya -->
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col v-for="item in QRdata.data" cols="12" sm="4" md="4">
            <v-card
              v-if="!!QRdata.data"
              class="mb-1"
              max-width="100%"
              height="50%"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">Asset Info</div>
                  <v-list-item-subtitle class="mb-1">
                    Category: {{ item.category }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="mb-1">
                    Holder: {{ item.holder }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="mb-1">
                    Company: {{ item.company }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="mb-1">
                    IP Address: {{ item.ip_address }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="mb-1">
                    Rack: {{ item.rack }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="mb-1">
                    Room: {{ item.room }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="mb-1">
                    Location: {{ item.location }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <v-card v-if="!!QRdata.data" max-width="100%" height="50%" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">Asset Description</div>
                  <!-- <v-list-item-subtitle class="mb-1">
                    Price: {{ item.price | toCurrency }}
                  </v-list-item-subtitle> -->
                  <v-list-item-subtitle class="mb-1">
                    Description: {{ item.description }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="mb-1">
                    Number: {{ item.number }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="mb-1">
                    Number Code: {{ item.number_code }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="mb-1">
                    Purchase: {{ item.purchase_date }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="mb-1">
                    Created: {{ item.created_at | moment("MMMM DD, YYYY") }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="mb-1">
                    Updated: {{ item.updated_at | moment("MMMM DD, YYYY") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "@/components/core/navbar/navbar-regular.vue";
import { mapState } from "vuex";

export default {
  name: "asset-detail-by-qr",
  components: {
    Navbar,
  },
  created() {
    this.getAssetByQR();
  },
  computed: {
    ...mapState("getAssetByQR", ["QRdata"]),
    currentRouteID() {
      return this.$router.currentRoute.params.assetID;
    },
    // remapItem() {
    //   return this.QRdata.map((i) => {
    //     return { ...this.QRdata, all_image: i.image.concat(i.invoice) };
    //   });
    // },
  },
  methods: {
    getAssetByQR() {
      this.$store.dispatch("getAssetByQR/getAssetQR", this.currentRouteID);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-carousel {
  height: unset !important;

  ::v-deep(.v-image) {
    height: unset !important;
  }
}

.v-main {
  background: var(--v-accent-base);

  .main {
    gap: 1rem;

    h5 {
      font-family: Comfortaa, cursive;
      font-size: 18px !important;
      font-weight: $font-black;
      text-transform: capitalize;
    }
  }
}
</style>
