<template>
  <v-list>
    <v-list-group :value="true" :prepend-icon="icon" color="white">
      <template v-slot:activator>
        <v-list-item-title>{{ activatorTitle }}</v-list-item-title>
      </template>

      <v-list-item-group>
        <slot />
      </v-list-item-group>
    </v-list-group>
  </v-list>
</template>

<script>
export default {
  name: "List",
  props: ["activatorTitle", "icon"],
};
</script>

<style lang="scss" scoped>
.subheader {
  padding-left: 16px;
  text-transform: capitalize;
}

.v-list-item__icon {
  margin-right: 14px !important;
  margin-left: 14px !important;
  margin-top: auto;
  margin-bottom: auto;
}

.v-list-item__title {
  font-family: $secondary-font;
  text-transform: capitalize;
  font-size: 12px;
}

.v-list-item {
  transition: all 0.5s ease 0s;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;

  .icon-image {
    filter: brightness(100);
  }

  &--active {
    background-color: var(--v-accent-base) !important;
    color: var(--v-primary-base) !important;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;

    .icon-image {
      filter: none;
    }
  }

  &--link:before {
    background: none;
  }

  &:hover {
    transform: translate(0px, -5px);
  }
}
</style>
