<template>
  <DialogCard :max-width="$vuetify.breakpoint.xs ? '100%' : '50%'" v-model="showDialog">
    <template #title> QR Code - {{ params.number }} </template>

    <v-container v-if="showDialog">
      <v-row>
        <v-col cols="3">
          <BaseSelect outlined clearable height="45" item-text="name" item-value="value" :items="[
            { name: 'Group / Holder', value: 'group_hash' },
            { name: 'Number', value: 'number_hash' },
          ]" @change="generateQR($event)">
            <p style="font-size: 12px !important">Generate by</p>
          </BaseSelect>
        </v-col>
      </v-row>
      <div class="d-flex justify-center align-center">
        <div v-if="QRdata.status" class="d-flex justify-center align-center flex-column gap-3">
          <self-building-square-spinner color="#0656f0" :animation-duration="2500" :size="60" />
          <p class="loader-text">Loading ...</p>
        </div>
        <div class="d-flex flex-column justify-center align-center py-4" v-else-if="!!QRdata.data">
          <v-img :src="`data:image/png;base64,${QRdata.data}`" :height="$vuetify.breakpoint.mobile ? 300 : 500"
            width="500" contain></v-img>
          <BaseButton style="background-color: teal; color: white;" depressed outlined rounded
            icon-prepend="iconoir-cloud-download" title="Download" @click="downloadQR(QRdata.data, params.number)" />
        </div>
        <p v-else-if="!QRdata.data && !QRdata.status" class="no-data py-10">
          No QR code Generated
        </p>
      </div>
    </v-container>
  </DialogCard>
</template>

<script>
import DialogCard from "@/components/base/dialog/dialog-card.vue";
import BaseSelect from "@/components/base/input/base-select.vue";
import { SelfBuildingSquareSpinner } from "epic-spinners";
import { saveAs } from "file-saver";
import { mapState } from "vuex";
import BaseButton from "@/components/base/button/base-button.vue";

export default {
  name: "Detail-QR-Dialog",
  props: ["params", "value"],
  components: {
    BaseButton,
    SelfBuildingSquareSpinner,
    BaseSelect,
    DialogCard,
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    ...mapState("generateQR", ["QRdata"]),
  },
  watch: {
    showDialog() {
      this.QRdata.data = "";
    },
  },
  methods: {
    generateQR(event) {
      this.$store.dispatch("generateQR/generateQR", {
        id: this.params[event],
        company: this.params["company"],
      });
    },
    downloadQR(base64, filename) {
      fetch(`data:image/png;base64,${base64}`).then((res) => {
        res.blob().then((res2) => {
          saveAs(res2, `${filename}.png`);
        });
      });
    },
  },
};
</script>

<style lang="scss">
.loader-text {
  font-size: 18px;
  font-weight: $font-medium;
}

.no-data {
  font-size: 24px;
  font-weight: $font-medium;
}
</style>