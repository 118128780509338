import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#0656f0",
        secondary: "#45D3FF",
        light: "#e5e5e5",

        fontPrimary: "#67748e",
        fontSecondary: "#344767",

        anchor: "#2760CE",
        accent: "#f4f6fd",
        gray: "#eff0f6",
        green: "#13a47e",

        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
});
