import axios from "axios";

export default {
  namespaced: true,
  state: {
    QRdata: {
      status: false,
      data: "",
    },
  },
  actions: {
    async generateQR({ state }, data) {
      state.QRdata = {
        ...state.QRdata,
        status: true,
        data: "",
      };

      try {
        await axios({
          method: "POST",
          url: "/eb-api/eb-asset/generate-qr",
          data: {
            id: data.id,
            company: data.company,
          },
        }).then((res) => {
          let response = res.data.data;
          state.QRdata = {
            ...state.QRdata,
            status: false,
            data: response,
          };
        });
      } catch (err) {
        state.QRdata = {
          ...state.QRdata,
          status: false,
        };
      }
    },
  },
};
