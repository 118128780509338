import axios from "axios";

export default {
  namespaced: true,
  state: {
    employeeTableData: {
      data: [],
      loading: false,
    },
  },
  actions: {
    async employeeTableAsset({ state }) {
      state.employeeTableData = {
        ...state.employeeTableData,
        loading: true,
      };

      try {
        await axios({
          method: "GET",
          url: "/eb-api/eb-asset/employee-asset",
        }).then((res) => {
          let resp = res.data.data;

          let result = [];
          resp.map((a) =>
            result.push({ name: a.name, asset: a.asset, total: a.count })
          );

          state.employeeTableData.data = result;
        });

        state.employeeTableData = {
          ...state.employeeTableData,
          loading: false,
        };
      } catch (error) {
        state.employeeTableData = {
          ...state.employeeTableData,
          loading: false,
        };
      }
    },
  },
};
