export const ASSET_DETAIL = [
  {
    category: "Asset Type",
    name: "Type",
    value: "type",
  },
  {
    category: "Asset Type",
    name: "Status",
    value: "status",
  },
  {
    category: "Asset Type",
    name: "Sub Category",
    value: "sub_category",
  },
  {
    category: "Asset Type",
    name: "Sub Cat. Code",
    value: "sub_category_code",
  },
  {
    category: "Asset Info",
    name: "Category",
    value: "category",
  },
  {
    category: "Asset Info",
    name: "Holder",
    value: "holder",
  },
  {
    category: "Asset Info",
    name: "Company",
    value: "company",
  },
  {
    category: "Asset Info",
    name: "IP Address",
    value: "ip_address",
  },
  {
    category: "Asset Info",
    name: "Rack",
    value: "rack",
  },
  {
    category: "Asset Info",
    name: "room",
    value: "room",
  },
  {
    category: "Asset Info",
    name: "Location",
    value: "location",
  },
  {
    category: "Asset Description",
    name: "Price",
    value: "price",
  },
  {
    category: "Asset Description",
    name: "Description",
    value: "description",
  },
  {
    category: "Asset Description",
    name: "Number",
    value: "number",
  },
  {
    category: "Asset Description",
    name: "Number Code",
    value: "number-code",
  },
  {
    category: "Asset Description",
    name: "Purchase",
    value: "purchase_date",
  },
  {
    category: "Asset Description",
    name: "Created",
    value: "created_at",
  },
  {
    category: "Asset Description",
    name: "Updated",
    value: "updated_at",
  },
];
