export const APP_SIDEBAR = [
  {
    name: "Overview",
    category: "Dashboard",
    path: "overview",
    complete_path: "/dashboard/overview",
    icon: "iconoir-home-simple-door",
    visibility: true,
  },
  {
    name: "Assets",
    category: "Dashboard",
    path: "assets",
    complete_path: "/dashboard/assets",
    icon: "iconoir-server",
    visibility: "is_asset_feature",
  },
  {
    name: "Stationary & Medicine",
    category: "Dashboard",
    path: "assets-stationary",
    complete_path: "/dashboard/stationary-medicine",
    icon: "iconoir-clipboard-check",
    visibility: "is_stationary_feature",
  },
  // {
  //   name: "Assets Management",
  //   category: "Assets Management",
  //   path: "collection",
  //   complete_path: "/pipeline-management/collection",
  //   icon: "iconoir-list-select",
  //   child: [
  //     {
  //       name: "Pipeline",
  //       path: "history/pipeline",
  //       complete_path: "/pipeline-management/history/pipeline",
  //       icon: "iconoir-circle",
  //       child: [],
  //     },
  //     {
  //       name: "Collection",
  //       path: "history/collection",
  //       complete_path: "/pipeline-management/history/collection",
  //       icon: "iconoir-circle",
  //       child: [],
  //     },
  //   ],
  // },
  {
    name: "User Management",
    category: "Admin Menu",
    path: "user-management",
    admin: true,
    complete_path: "/user-management",
    icon: "iconoir-user-circle",
  },
];

