import DetailTable from "./detail-table.vue";
import Vue from "vue";

export const events = new Vue({
  name: "detail-table-events",
});

export default {
  install(Vue, args = {}) {
    Vue.component(args.componentName, DetailTable);

    let params = args;

    let check = (params) => {
      // define emit name to detail-asset-events
      events.$emit("open", params);
    };

    const name = "detailTable";
    Vue.prototype["$" + name] = check;
    Vue[name] = check;
  },
};
