<template>
  <v-app>
    <BaseCenterWrapper>
      <h1 class="app-title white--text text-center">eBassets</h1>
      <router-view></router-view>
    </BaseCenterWrapper>
    <div class="wave-1"></div>
    <div class="wave-2"></div>
  </v-app>
</template>

<script>
import BaseCenterWrapper from "@/components/base/wrapper/base-center-wrapper.vue";

export default {
  name: "AuthLayout",
  components: {
    BaseCenterWrapper,
  },
  // async mounted() {
  //   this.$loading(true);
  //   await setTimeout(() => this.$loading(false), 1000);
  // },
};
</script>

<style lang="scss" scoped>
#app {
  position: relative;
  min-height: 100vh;
  background: linear-gradient(181deg, #0656f0, #a5c4ff);

  .app-title {
    margin: 0;
    font-family: $primary-font;
    font-size: $sizing-standard * 2 !important;
    font-weight: $font-bold;
    letter-spacing: 0em;
  }

  .wave-1 {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    opacity: 0.5;
    height: 250px;
    background: url("@/assets/image/wave-1.svg") 0% 0% / cover no-repeat;
    background-repeat: no-repeat;
    filter: brightness(100);

    // @include mobile {
    //   background-position: center;
    // }
  }

  .wave-2 {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    opacity: 0.5;
    height: 280px;
    background: url("@/assets/image/wave-2.svg") 0% 0% / cover no-repeat;
    background-repeat: no-repeat;
    filter: brightness(100);

    // @include mobile {
    //   background-position: center;
    // }
  }
}
</style>
