<template>
  <DialogCard v-model="showCreateDialog">
    <template #title> Add New Item </template>
    <v-container>
      <v-form class="d-flex flex-column gap-5" ref="form" v-model="valid">
        <section class="d-flex flex-column gap-1">
          <p>Asset Info</p>
          <v-row>
            <v-col cols="12" sm="6">
              <BaseSelect
                label="Type"
                item-text="name"
                item-value="name"
                height="45"
                outlined
                v-model="form.category"
                :items="classificationData.data.category"
                :rules="[(v) => !!v || 'category is required']"
              >
                <p class="font-3">Category <b class="red--text">*</b></p>
              </BaseSelect>
            </v-col>
            <v-col cols="12" sm="6">
              <BaseSelect
                label="Type"
                item-text="name"
                item-value="name"
                height="45"
                outlined
                v-model="form.sub_category"
                :items="classificationData.data.sub_category"
                :rules="[(v) => !!v || 'sub-category is required']"
              >
                <p class="font-3">Sub Category <b class="red--text">*</b></p>
              </BaseSelect>
            </v-col>
            <v-col cols="12" sm="12">
              <BaseTextField
                height="45"
                type="text"
                outlined
                v-model="form.name"
                :rules="[(v) => !!v || 'name is required']"
              >
                <p class="font-3">Name <b class="red--text">*</b></p>
              </BaseTextField>
            </v-col>
          </v-row>
        </section>

        <section class="d-flex flex-column gap-1">
          <p>Asset Description</p>
          <v-row>
            <v-col cols="12" sm="4">
              <BaseDatepicker
                v-model="form.purchase_date"
                :show-current="false"
                :rules="[(v) => !!v || 'purchase date is required']"
              >
                <p class="font-3">Purchase Date <b class="red--text">*</b></p>
              </BaseDatepicker>
            </v-col>
            <v-col cols="12" sm="4">
              <BaseSelect
                item-text="name"
                item-value="name"
                height="45"
                outlined
                :rules="[(v) => !!v || 'Required']"
                :items="listItems.list_location"
                v-model="form.location"
              >
                <p class="font-3">Location <b class="red--text">*</b></p>
              </BaseSelect>
            </v-col>
            <v-col cols="12" sm="4">
              <BaseSelect
                item-text="name"
                item-value="name"
                height="45"
                outlined
                :rules="[(v) => !!v || 'Required']"
                :items="[
                  { name: 'Office', value: 'office' },
                  { name: 'Marketing', value: 'marketing' },
                ]"
                v-model="form.division"
              >
                <p class="font-3">Division <b class="red--text">*</b></p>
              </BaseSelect>
            </v-col>
            <v-col cols="12" sm="3">
              <BaseSelect
                item-text="name"
                item-value="name"
                height="45"
                outlined
                v-model="form.brand"
                :items="classificationData.data.brand"
                :rules="[(v) => !!v || 'brand is required']"
              >
                <p class="font-3">Brand <b class="red--text">*</b></p>
              </BaseSelect>
            </v-col>
            <v-col cols="12" sm="3">
              <BaseTextField
                height="45"
                type="number"
                outlined
                v-model="form.price"
                :rules="[(v) => !!v || 'price is required']"
              >
                <p class="font-3">Price <b class="red--text">*</b></p>
              </BaseTextField>
            </v-col>
            <v-col cols="12" sm="3">
              <BaseTextField
                height="45"
                type="number"
                outlined
                v-model="form.qty"
                :rules="[(v) => !!v || 'quantity is required']"
              >
                <p class="font-3">Qty <b class="red--text">*</b></p>
              </BaseTextField>
            </v-col>
            <v-col cols="12" sm="3">
              <BaseSelect
                height="45"
                type="text"
                outlined
                item-text="name"
                item-value="value"
                v-model="form.type"
                :items="typeItems"
                :rules="[(v) => !!v || 'type is required']"
              >
                <p class="font-3">Type <b class="red--text">*</b></p>
              </BaseSelect>
            </v-col>
          </v-row>
        </section>

        <section class="d-flex flex-column gap-1">
          <p>Asset Attachment</p>
          <v-row>
            <v-col cols="12" sm="6">
              <BaseFileInput
                counter
                multiple
                outlined
                placeholder="Insert Image"
                prepend-inner-icon="iconoir-camera"
                height="45px"
                @change="formImage.image = $event"
              >
              </BaseFileInput>
            </v-col>
            <v-col cols="12" sm="6">
              <BaseFileInput
                counter
                multiple
                outlined
                placeholder="Insert Invoice"
                prepend-inner-icon="iconoir-camera"
                height="45px"
                @change="formImage.invoice = $event"
              />
            </v-col>
          </v-row>
        </section>
      </v-form>
    </v-container>

    <template #action>
      <v-container class="d-flex flex-row gap-2">
        <BaseButton
          title="Submit"
          color="primary"
          height="40px"
          depressed
          rounded
          @click="submitData()"
        />
        <BaseButton
          title="cancel"
          height="40px"
          depressed
          rounded
          @click="showCreateDialog = false"
        />
      </v-container>
    </template>
  </DialogCard>
</template>

<script>
import DialogCard from "@/components/base/dialog/dialog-card.vue";
import BaseSelect from "@/components/base/input/base-select.vue";
import BaseTextField from "@/components/base/input/base-text-field.vue";
import BaseDatepicker from "@/components/base/input/base-datepicker.vue";
import BaseButton from "@/components/base/button/base-button.vue";
import BaseFileInput from "@/components/base/input/base-file-input.vue";
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "create-asset-dialog",
  data() {
    return {
      valid: false,
      form: {
        category: "",
        sub_category: "",
        name: "",
        purchase_date: "",
        brand: "",
        price: "",
        qty: "",
        type: "",
        location: "",
        division: "",
      },
      formImage: {
        image: [],
        invoice: [],
      },
      typeItems: [
        { name: "ATK", value: "atk" },
        { name: "Medicine", value: "medicine" },
      ],
      listItems: {
        lsit_location: [],
      },
    };
  },
  props: ["value", "params"],
  components: {
    DialogCard,
    BaseSelect,
    BaseTextField,
    BaseDatepicker,
    BaseButton,
    BaseFileInput,
  },
  computed: {
    showCreateDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    ...mapState("assetGetClassificationAtk", ["classificationData"]),
  },
  watch: {
    params(newValue, oldValue) {
      Object.keys(this.form).map((key) => {
        this.form[key] = this.params[key];
      });

      Object.keys(this.formImage).map((key) => {
        let i = 1;
        this.formImage[key] = this.getFileFromBase64(
          this.params[key],
          "image-" + i
        );
        i++;
      });

      this.submit = "update";
    },
  },
  created() {
    this.getClassificationData();
  },
  methods: {
    getClassificationData() {
      axios
        .get("/eb-api/eb-asset/get-data?type=atk", {})
        .then((response) => {
          this.listItems.list_location = response.data.data.location;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async submitData() {
      this.$refs.form.validate();
      if (!this.valid) {
        return this.$alert({
          type: "warning",
          title: "Warning",
          message: "Please fill all required field",
          button: {
            yes: "Okay",
          },
        });
      }

      let formData = new FormData();
      if (this.formImage.image.length > 0) {
        this.formImage.image.forEach((i) => {
          formData.append("image", i);
        });
      }

      if (this.formImage.invoice.length > 0) {
        this.formImage.invoice.forEach((i) => {
          formData.append("invoice", i);
        });
      }

      formData.append("atk", JSON.stringify(this.form));

      await axios({
        method: "POST",
        url: "/eb-api/eb-asset/create-atk",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          this.showCreateDialog = false;
          this.$refs.form.reset();
          this.$refs.form.resetValidation();
          this.$emit("onsuccess");
        })
        .catch((err) => {
          this.$alert({
            type: "warning",
            title: "Warning",
            message: "Failed to add item",
            button: {
              yes: "Okay",
            },
          });
        });
    },

    getFileFromBase64(stringbase64, fileName) {
      const imageContent = atob(stringbase64, "base64");
      const buffer = new ArrayBuffer(imageContent.length);
      const view = new Uint8Array(buffer);

      for (let n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n);
      }
      const type = "image/jpeg";
      const blob = new Blob([buffer], { type });
      return new File([blob], fileName, {
        lastModified: new Date().getTime(),
        type,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
