<template>
  <div class="wrapper">
    <v-tabs
      class="tabs"
      v-model="tabs"
      background-color="accent"
      hide-slider
      @change="$emit('tabchange', tab[$event])"
    >
      <v-tab
        class="tab-label"
        v-for="(item, index) in tab"
        :key="item"
        :ripple="false"
        :class="{ active: tabs === index }"
      >
        <div class="acvtive-tab" v-if="tabs === index"></div>
        <span class="tab-text">{{ item }}</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items class="tabs-item py-4" touchless v-model="tabs">
      <slot />
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: ["tab", "value"],
  data() {
    return {};
  },
  computed: {
    tabs: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .tabs {
    position: relative;
    border-radius: 10px;
    border-top-color: rgba(0, 0, 0, 0.12);
    border-right-color: rgba(0, 0, 0, 0.12);
    border-left-color: rgba(0, 0, 0, 0.12);

    .tab-label {
      position: relative;
      color: black;
      text-transform: capitalize !important;
      font-size: 11px !important;
      margin-inline: auto !important;
      min-width: 0 !important;
      padding: 0 !important;

      .tab-text {
        padding: 140px;
      }

      .acvtive-tab {
        position: absolute;
        width: 100%;
        height: 80%;
        background-color: white;
        border-radius: 10px;
        z-index: -1;
      }
    }
  }

  .tabs-item {
    background-color: transparent !important;
  }
}
</style>
