import axios from "axios";

export default {
  namespaced: true,
  state: {
    atkData: {
      data: [],
      loading: false,
    },
  },
  actions: {
    async getAtk({ state }, data) {
      state.atkData = {
        ...state.atkData,
        loading: true,
      };

      try {
        await axios({
          method: "GET",
          url: "/eb-api/eb-asset/get-atk",
          params: {
            page: data.page || "1",
            size: data.size || "5",
            search: data.search || undefined,
            type: data.type || "*",
          },
        }).then((res) => {
          let resp = res.data.data;
          state.atkData.data = resp;
        });

        state.atkData = {
          ...state.atkData,
          loading: false,
        };
      } catch (error) {
        state.atkData = {
          ...state.atkData,
          loading: false,
        };
      }
    },
  },
};
