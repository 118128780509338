<template>
  <v-text-field class="custom-text-field" flat dense hide-spin-buttons :height="height" :outlined="outlined"
    :error="error" :error-messages="errorMessage" :clearable="appendIcon || prependInnerIcon ? false : true"
    :disabled="disabled" :hint="hint" :label="label" :placeholder="placeholder" :prefix="prefix" :readonly="readonly"
    :rules="rules" :suffix="suffix" :type="type" :autofocus="autofocus" :hide-details="hideDetails"
    :append-icon="appendIcon" :prepend-inner-icon="prependInnerIcon" :value="value" v-on="$listeners">
    <template v-slot:label>
      <slot></slot>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "BaseTextField",
  props: [
    "disabled",
    "label",
    "hint",
    "placeholder",
    "outlined",
    "prefix",
    "readonly",
    "height",
    "rules",
    "suffix",
    "type",
    "autofocus",
    "hideDetails",
    "appendIcon",
    "prependInnerIcon",
    "errorMessage",
    "error",
    "value",
  ],
};
</script>

<style lang="scss" scoped>
.custom-text-field {
  border-radius: 8px !important;

  ::v-deep(.v-input__slot) {
    min-height: v-bind(height) !important;

    .v-label {
      font-size: 14px;
      top: auto !important;
      bottom: auto !important;

      &--active {
        transform: translateY(-23px) scale(0.75) !important;
      }
    }

    .v-input__prepend-inner {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }

    .v-input__append-inner {
      margin-top: auto !important;
      margin-bottom: auto !important;

      .v-icon {
        font-size: 18px !important;
      }
    }

    // @include mobile {
    //   min-height: v-bind(height) - 2.87px !important;
    // }
  }
}
</style>
