<template>
  <v-menu
    content-class="menu elevation-0"
    :close-on-content-click="false"
    offset-y
    left
  >
    <template v-slot:activator="{ on }">
      <BaseButtonIcon
        icon="iconoir-settings"
        outlined
        v-on="on"
      ></BaseButtonIcon>
    </template>

    <BaseCard class="pa-6">
      <div class="d-flex flex-column gap-3">
        <div
          v-for="(item, index) of filter_config"
          :key="index"
          class="d-flex flex-column"
        >
          <div
            v-if="item.name == 'interval'"
            class="d-flex flex-column"
            style="gap: 1rem"
          >
            <p>Interval</p>

            <v-btn-toggle
              color="primary"
              :mandatory="!!filterData.interval"
              v-model="filterData.interval"
            >
              <v-btn class="font-3" value="d">1D</v-btn>
              <v-btn class="font-3" value="w">1W</v-btn>
              <v-btn class="font-3" value="m">1M</v-btn>
              <v-btn class="font-3" value="y">1Y</v-btn>
              <v-btn v-if="!!item.adv" class="font-3" value="adv">ADV</v-btn>
            </v-btn-toggle>
            <v-expand-transition>
              <div v-show="!!item.adv && filterData.interval == 'adv'">
                <BaseDatepicker
                  class="datepick-1"
                  label="start date"
                  :showCurrent="false"
                  :max="filterData.end"
                  v-model="filterData.start"
                >
                  <p class="font-3">Start Date</p>
                </BaseDatepicker>
                <div class="py-2"></div>
                <BaseDatepicker
                  label="end date"
                  :showCurrent="false"
                  :min="filterData.start"
                  v-model="filterData.end"
                >
                  <p class="font-3">End Date</p>
                </BaseDatepicker>
              </div>
            </v-expand-transition>
          </div>

          <BaseSelect
            v-if="item.name == 'category'"
            outlined
            clearable
            height="45"
            :items="Object.keys(itemsCategory)"
            v-model="filterData.category"
            @input="filterData.subCategory = undefined"
          >
            <p>Category</p>
          </BaseSelect>

          <BaseSelect
            v-if="item.name == 'sub_category'"
            outlined
            height="45"
            item-text="name"
            return-object
            :items="itemsCategory[filterData.category]"
            v-model="filterData.subCategory"
          >
            <p>Sub Category</p>
          </BaseSelect>
        </div>
      </div>

      <template #action>
        <BaseButton
          v-if="filterData.subCategory"
          title="Print"
          color="primary"
          depressed
          outlined
          @click="printData()"
        />
        <BaseButton
          class="ml-auto"
          title="Apply"
          color="primary"
          depressed
          @click="emitApply()"
        />
      </template>
    </BaseCard>
  </v-menu>
</template>

<script>
import axios from "axios";
import BaseCard from "../card/base-card.vue";
import BaseButtonIcon from "../button/base-button-icon.vue";
import BaseDatepicker from "../input/base-datepicker.vue";
import BaseButton from "../button/base-button.vue";
import { FILTER_CONFIG } from "@/config/filter-config";
import BaseSelect from "../input/base-select.vue";
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "filter-chart",
  components: {
    BaseCard,
    BaseButtonIcon,
    BaseDatepicker,
    BaseButton,
    BaseSelect,
  },
  data() {
    return {
      filterData: {
        start: undefined,
        end: undefined,
        interval: undefined,
        category: undefined,
        subCategory: undefined,
      },
      filter_config: [],
      categoryModel: "",
    };
  },
  props: ["id"],
  mounted() {
    this.filter_config = FILTER_CONFIG[this.id].filter || [];
    if (
      this.filter_config.filter((i) =>
        ["category", "sub_category"].includes(i.name)
      )
    ) {
    }
  },
  computed: {
    itemsCategory() {
      return this.$store.state.assetGetClassification.classificationData.data;
    },
  },
  methods: {
    printData() {
      let start, end;
      switch (this.filterData.interval) {
        case "d":
          start = moment().subtract(1, "day").format("YYYY-MM-DD");
          end = moment().format("YYYY-MM-DD");
          break;
        case "w":
          start = moment().subtract(1, "week").format("YYYY-MM-DD");
          end = moment().format("YYYY-MM-DD");
          break;
        case "m":
          start = moment().startOf("month").format("YYYY-MM-DD");
          end = moment().endOf("month").format("YYYY-MM-DD");
          break;
        case "y":
          start = moment().startOf("year").format("YYYY-MM-DD");
          end = moment().endOf("year").format("YYYY-MM-DD");
          break;
        default:
          start = this.filterData.start;
          end = this.filterData.end;
          break;
      }

      let params = {
        start: start,
        end: end,
        type: this.filterData.category,
        filter: this.filterData.subCategory.name,
        sort: "desc",
        sort_by: "created_at",
      };

      let queryString = Object.keys(params)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        )
        .join("&");

      axios
        .get(
          `/eb-api/eb-asset/print-asset?size=10000&keyword=&${queryString}`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "data.xls");
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          if (error.response) {
            const statusCode = error.response.status;

            if (statusCode === 500) {
              this.$alert({
                type: "warning",
                title: "Error",
                message: "Internal Server Error: Failed to download asset",
                button: {
                  yes: "Okay",
                },
              });
            } else {
              this.$alert({
                type: "warning",
                title: "Error",
                message: `Failed to download asset (HTTP status: ${statusCode})`,
                button: {
                  yes: "Okay",
                },
              });
            }
          } else {
            this.$alert({
              type: "warning",
              title: "Error",
              message: "No response received: Failed to download asset",
              button: {
                yes: "Okay",
              },
            });
          }
        });
    },

    emitApply() {
      let params = {};
      console.log(this.filterData);

      this.filter_config.forEach((i) => {
        switch (i.name) {
          case "category":
            params["category"] = this.filterData.category;
            break;
          case "sub_category":
            params["sub_category"] =
              this.filterData.category == "location" ||
              this.filterData.category == "sub_category"
                ? this.filterData?.subCategory?.name
                : this.filterData?.subCategory?.code == "" ||
                  this.filterData?.subCategory?.code == "-"
                ? this.filterData?.subCategory?.name
                : this.filterData?.subCategory?.code;
            break;

          default:
            break;
        }
      });

      this.$emit("change", params);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  font-size: 12px;
}
</style>
>
