import axios from "axios";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    purchaseData: {
      key: [],
      count: [],
      loading: false,
    },
  },
  actions: {
    async purchaseAsset({ state }, data) {
      state.purchaseData = {
        ...state.purchaseData,
        loading: true,
      };

      try {
        await axios({
          method: "POST",
          url: "/eb-api/eb-asset/purchase-asset",
          data: {
            start: moment(data.start).format("YYYY-MM-DD 00:00:00"),
            end: moment(data.end).format("YYYY-MM-DD 00:00:00"),
            interval: data.interval,
          },
        }).then((res) => {
          let data = res.data.data;

          const keys = data.map((item) =>
            moment(item.key).format("YYYY-MM-DD")
          );
          const count = data.map((item) => item.count);

          state.purchaseData = {
            ...state.purchaseData,
            key: keys,
            count: count,
            loading: false,
          };
        });
      } catch (error) {
        state.purchaseData = {
          ...state.purchaseData,
          loading: false,
        };
      }
    },
  },
};
