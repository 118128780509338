<template>
  <v-checkbox
    class="checkbox mt-0 pt-0"
    :hide-details="hideDetails"
    :ripple="ripple"
    :label="label"
    v-model="checked"
    v-on="$listeners"
  >
    <template v-slot:label> </template>
  </v-checkbox>
</template>

<script>
export default {
  name: "base-checkbox",
  props: ["hideDetails", "hide-spin-buttons", "ripple", "label", "value"],
  computed: {
    hasLabel() {
      return !!this.$slots.label;
    },
    checked: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  ::v-deep(.v-input__slot) {
    i {
      font-size: 20px !important;
    }
    .v-label {
      font-family: $secondary-font;
      font-size: 12px;
    }
  }
}
</style>
