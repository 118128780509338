import axios from "axios";

export default {
  namespaced: true,
  state: {
    statusData: {
      data: [],
      loading: false,
    },
  },
  actions: {
    async statusAsset({ state }) {
      state.statusData = {
        ...state.statusData,
        loading: true,
      };

      try {
        await axios({
          method: "GET",
          url: "/eb-api/eb-asset/status-asset",
        }).then((res) => {
          let resp = res.data.data;

          let result = [];
          resp.map((a) => result.push({ name: a.status, value: a.count }));

          state.statusData.data = result;
        });

        state.statusData = {
          ...state.statusData,
          loading: false,
        };
      } catch (error) {
        state.statusData = {
          ...state.statusData,
          loading: false,
        };
      }
    },
  },
};
