<template>
  <div>
    {{ label }}
    <v-autocomplete class="custom-autocomplete" outlined dense clearable :no-data-text="noDataText"
      :hide-no-data="hideNoData" :hide-details="hideDetails" :search-input="searchInput" :label="label"
      :item-text="itemText" :item-value="itemValue" :rules="rules" :items="items" :disabled="disabled"
      :return-object="returnObject" v-model="selected" v-on="$listeners">
      <template v-if="loading" v-slot:append>
        <v-progress-circular class="mr-2" size="15" width="2" color="#1976d2" indeterminate />
      </template>
      <template v-slot:label>
        <slot />
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  name: "default-autocomplete",
  props: [
    "label",
    "itemText",
    "itemValue",
    "rules",
    "items",
    "disabled",
    "hideDetails",
    "returnObject",
    "loading",
    "hideNoData",
    "noDataText",
    "searchInput",
    "value",
  ],
  data() {
    return {};
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-autocomplete {
  font-family: $secondary-font;
  font-size: 0.8rem;
  // border-radius: 8px !important;

  ::v-deep(.v-select__slot) {
    min-height: v-bind(height)!important;

    .v-label {
      top: auto !important;
      bottom: auto !important;

      &--active {
        transform: translateY(-23px) scale(0.75) !important;
      }
    }

    .v-input__prepend-inner {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }

    .v-input__append-inner {
      margin-top: auto !important;
      margin-bottom: auto !important;

      .v-icon {
        font-size: 18px !important;
      }
    }

    @include mobile {
      min-height: $standard-field - 2.87px !important;
    }
  }
}
</style>
