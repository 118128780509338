<template>
  <v-app-bar id="appbar" color="accent" height="76px" elevate-on-scroll app>
    <v-app-bar-nav-icon
      @click="drawerState = !drawerState"
    ></v-app-bar-nav-icon>

    <div class="main ml-3">
      <h5>{{ getCurrentRoute }}</h5>
    </div>

    <div class="wrapper-avatar ml-auto">
      <div class="mobile" v-if="$vuetify.breakpoint.mobile">
        <v-menu bottom max-width="500px" min-width="150px" offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon plain :ripple="false" v-on="on">
              <v-icon small>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-card>
            <div class="py-4 px-4 text-center">
              <v-avatar :color="stringToColor(user.initials)" size="35">
                <span class="white--text">{{ user.initials }}</span>
              </v-avatar>
              <h5 class="mt-2 mb-1">{{ user.fullName }}</h5>
              <p>
                {{ user.email }}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-list class="text-left py-0" dense rounded>
                <div v-show="!getRouteDashboard">
                  <v-list-item link>
                    <v-list-item-title class="d-flex">
                      <v-icon class="pr-4" small>mdi-file-tree-outline</v-icon>
                      <p>Project</p>
                    </v-list-item-title>
                  </v-list-item>
                </div>

                <!-- <div v-show="getRouteDashboard">
                  <v-list-item link :to="{ path: '/account' }">
                    <v-list-item-title class="d-flex">
                      <v-icon class="pr-4" small>mdi-account-cog</v-icon>
                      <p>Account</p>
                    </v-list-item-title>
                  </v-list-item>
                </div> -->

                <div>
                  <v-list-item @click="handleLogout()">
                    <v-list-item-title class="d-flex">
                      <v-icon class="pr-4" small>mdi-logout</v-icon>
                      <p>Logout</p>
                    </v-list-item-title>
                  </v-list-item>
                </div>
              </v-list>
            </div>
          </v-card>
        </v-menu>
      </div>

      <div class="desktop" v-else>
        <v-menu
          content-class="menu elevation-0"
          bottom
          max-width="200px"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn
              large
              rounded
              elevation="0"
              color="transparent"
              :ripple="false"
              text
              v-on="on"
            >
              <v-avatar
                class="mr-2"
                :color="stringToColor(user.initials)"
                size="36"
              >
                <span class="white--text">{{ user.initials }}</span>
              </v-avatar>
              <span>
                <p class="username black--text">{{ user.username }}</p>
              </span>
              <span>
                <v-icon color="black" small>mdi-chevron-down</v-icon>
              </span>
            </v-btn>
          </template>

          <v-list class="text-left py-0" dense>
            <div v-show="!getRouteDashboard">
              <v-list-item class="list-item" link>
                <v-list-item-title class="d-flex">
                  <v-icon class="pr-4" small>mdi-file-tree-outline</v-icon>
                  <p>Project</p>
                </v-list-item-title>
              </v-list-item>
            </div>

            <!-- <div v-show="getRouteDashboard">
              <v-list-item link>
                <v-list-item-title class="d-flex">
                  <v-icon class="pr-4" small>mdi-account-cog</v-icon>
                  <p>Account</p>
                </v-list-item-title>
              </v-list-item>
            </div> -->

            <v-list-item @click="handleLogout()">
              <v-list-item-title class="d-flex">
                <v-icon class="pr-4" small>mdi-logout</v-icon>
                <p>Logout</p>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import router from "@/router";
import { getAccount } from "@/helpers/account";

export default {
  name: "appbar",
  data() {
    return {
      user: {
        initials: "",
        fullName: "",
        email: "",
      },
    };
  },
  computed: {
    getCurrentRoute() {
      return this.$route.name;
    },
    drawerState: {
      get() {
        return this.$store.getters["drawer/drawerState"];
      },
      set(v) {
        return this.$store.commit("drawer/toggleDrawerState", v);
      },
    },
  },
  mounted() {
    let userData = getAccount();

    // console.log(!!localStorage.getItem('user') || !!sessionStorage.getItem('user'));

    this.user = {
      ...this.user,
      fullName: userData?.fullname,
      username: userData?.username,
      email: userData?.email,
      initials:
        userData?.fullname.split(" ")?.length == 1
          ? userData?.fullname?.split(" ")[0][0]
          : `${userData?.fullname?.split(" ")[0][0]} ${
              userData?.fullname?.split(" ")[1][0]
            }`,
    };
  },
  methods: {
    stringToColor(string) {
      let hash = 0;
      let i;
      for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }
      let color = "#";
      for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
      }
      return color;
    },
    getRouteDashboard() {
      return router.currentRoute.path.includes("dashboard");
    },

    handleLogout() {
      this.$alert({
        type: "warning",
        title: "Warning",
        message: "Are you sure to logout?",
        button: {
          yes: "yes",
          no: "no",
        },
        callback: () => {
          localStorage.clear();
          sessionStorage.clear();
          router.push("/login");
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  border: 1px solid #e0e0e0;
}

.main {
  gap: 1rem;

  h5 {
    font-family: Comfortaa, cursive;
    font-size: 18px !important;
    font-weight: $font-black;
    text-transform: capitalize;
  }
}

#appbar {
  z-index: 5 !important;

  .wrapper {
    position: relative;
    border-radius: 1rem;
    background-color: rgba(212, 212, 212, 0.17);
    margin-left: 0px;

    .search {
      background-color: rgb(227, 231, 234);
      border-radius: inherit;
      padding: 0px 12px;
      height: 100%;
      position: absolute;
      pointer-events: none;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
    }

    .field {
      border-radius: inherit;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.4375em;
      letter-spacing: 0.00938em;
      box-sizing: border-box;
      position: relative;
      cursor: text;
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      color: inherit;
      padding-left: 2rem;
      width: 12rem;

      transition: all 0.2s;
    }
    .v-input--is-focused {
      width: 16rem !important;
    }
  }

  .wrapper-avatar {
    p {
      text-transform: capitalize;
    }
  }
}
</style>
