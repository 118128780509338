import DetailAsset from "./detail-asset.vue";
import Vue from "vue";

export const events = new Vue({
  name: "detail-asset-events",
});

export default {
  install(Vue, args = {}) {
    Vue.component(args.componentName, DetailAsset);

    let params = args;

    let check = (params) => {
      // define emit name to detail-asset-events
      events.$emit("open", params);
    };

    const name = "detail";
    Vue.prototype["$" + name] = check;
    Vue[name] = check;
  },
};
