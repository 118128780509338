<template>
  <v-dialog
    overlay-opacity="0.5"
    content-class="elevation-0"
    scrollable
    :max-width="$vuetify.breakpoint.xs ? '100%' : '70%'"
    v-model="open"
  >
    <BaseCard>
      <template #title>
        <slot name="title"></slot>
      </template>
      <v-container>
        <slot></slot>
      </v-container>
      <template #action>
        <slot name="action"></slot>
      </template>
    </BaseCard>
  </v-dialog>
</template>

<script>
import BaseCard from "../card/base-card.vue";

export default {
  name: "dialog-card",
  data() {
    return {};
  },
  props: ["value"],
  components: {
    BaseCard,
  },
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style></style>
