<template>
  <DialogCard v-model="showCreateDialog">
    <template #title> Classification </template>

    <div class="d-flex flex-column gap-6 ma-3">
      <div class="d-flex flex-column gap-3">
        <p class="font-4">Add Classification</p>

        <div class="d-flex flex-column gap-3">
          <v-form ref="form" v-model="formValid">
            <v-row>
              <v-col cols="12" sm="4">
                <BaseTextField
                  outlined
                  height="45"
                  :rules="[(v) => !!v || 'required']"
                  v-model="form.name"
                >
                  <p>Name Classification <b class="red--text">*</b></p>
                </BaseTextField>
              </v-col>
              <v-col cols="12" sm="4">
                <BaseTextField outlined height="45" v-model="form.code">
                  <p>Code Classification</p>
                </BaseTextField>
              </v-col>
              <v-col cols="12" sm="4">
                <BaseCombobox
                  outlined
                  height="45"
                  :rules="[(v) => !!v || 'required']"
                  :items="Object.keys(this.classificationData.data)"
                  v-model="form.type"
                >
                  <p>Type Classification <b class="red--text">*</b></p>
                </BaseCombobox>
              </v-col>
            </v-row>
          </v-form>
          <BaseButton
            v-if="type == 'add'"
            title="Add Classification"
            color="primary"
            depressed
            rounded
            block
            :disabled="!formValid"
            @click="addClassification"
          />
          <BaseButton
            v-else-if="type == 'update'"
            title="Update Classification"
            color="primary"
            depressed
            rounded
            block
            :disabled="!formValid"
            @click="updateClassification"
          />
        </div>
      </div>

      <div class="d-flex flex-column gap-3">
        <p class="font-4">Edit or Delete Classification</p>

        <div class="d-flex flex-column gap-3">
          <v-row>
            <v-col cols="12" sm="3">
              <BaseTextField
                placeholder="Search"
                prepend-inner-icon="iconoir-search"
                v-model="searchModel"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <BaseSelect
                placeholder="Filter category"
                :items="Object.keys(classificationData.data)"
                v-model="filterModel"
              ></BaseSelect>
            </v-col>
          </v-row>

          <TableData
            :items="filterData"
            :headers="headerClassification"
            :itemsPerPageOptions="[5, 10, 20, -1]"
            :height="390"
          >
            <template v-slot:[`item.action`]="{ item }">
              <BaseButtonIcon
                icon="iconoir-trash"
                color="red"
                depressed
                @click="handleDelete(item)"
              />
              <BaseButtonIcon
                icon="iconoir-edit-pencil"
                color="blue"
                @click="handleUpdate(item, filterModel)"
              />
            </template>
          </TableData>
        </div>
      </div>
    </div>
  </DialogCard>
</template>

<script>
import DialogCard from "@/components/base/dialog/dialog-card.vue";
import BaseSelect from "@/components/base/input/base-select.vue";
import BaseTextField from "@/components/base/input/base-text-field.vue";
import BaseDatepicker from "@/components/base/input/base-datepicker.vue";
import BaseButton from "@/components/base/button/base-button.vue";
import Tabs from "@/components/base/tabs/Tabs.vue";
import TableData from "@/components/base/table/table-data.vue";
import BaseButtonIcon from "@/components/base/button/base-button-icon.vue";
import BaseCombobox from '@/components/base/input/base-combobox.vue';
import { mapActions, mapState } from "vuex";
import axios from "axios";

export default {
  name: "add-classification",
  data() {
    return {
      // tab: 0,
      // tabs: ["Add", "Rusak"],
      formValid: false,
      type: "add",
      id: "",
      searchModel: "",
      filterModel: "",
      form: {
        name: "",
        code: "",
        type: "",
      },
      headerClassification: [
        {
          text: "Name",
          value: "name",
          align: "left",
          width: "40%",
        },
        {
          text: "Code",
          value: "code",
          align: "left",
          width: "40%",
        },
        {
          text: "Action",
          value: "action",
          align: "left",
          sortable: false,
          width: "20%",
        },
      ],
    };
  },
  props: ["value"],
  components: {
    DialogCard,
    BaseSelect,
    BaseCombobox,
    BaseTextField,
    BaseDatepicker,
    BaseButton,
    Tabs,
    TableData,
    BaseButtonIcon,
  },
  async mounted() {
    await this.getData();
    this.filterModel = Object.keys(this.classificationData.data)[0];
  },
  computed: {
    ...mapState("assetGetClassificationAtk", ["classificationData"]),
    filterData() {
      return this.classificationData.data[this.filterModel]?.filter((i) =>
        i.name.toLowerCase().includes(this.searchModel.toLowerCase())
      );
    },
    showCreateDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    showCreateDialog(oldValue, newValue) {
      this.form = { ...this.form, name: "", code: "", type: "" };
      this.type = "add";
    },
  },
  methods: {
    ...mapActions("assetGetClassificationAtk", ["getData"]),
    handleDelete(e) {
      this.$alert({
        type: "warning",
        title: "Warning",
        message: `Are you sure to delete ${e.name} ? `,
        button: {
          yes: "yes",
          no: "no",
        },
        callback: () => {
          axios({
            method: "DELETE",
            url: "/eb-api/eb-asset/delete-data",
            params: {
              id: e.id,
            },
          }).then((res) => {
            setTimeout(() => {
              this.getData();
            }, 700);
          });
        },
      });
    },
    handleUpdate(e1, e2) {
      this.form = { ...this.form, name: e1.name, code: e1.code, type: e2 };
      this.id = e1.id;
      this.type = "update";
    },
    addClassification() {
      axios({
        method: "POST",
        url: "/eb-api/eb-asset/create-data",
        data: {
          name: this.form.name,
          code: this.form.code,
          type: this.form.type,
          filter_type: "atk",
        },
      })
        .then((res) => {
          this.$alert({
            type: "success",
            title: "Success",
            message: "Success add classification",
            button: {
              yes: "yes",
            },
            callback: () => {
              this.form = { ...this.form, name: "", code: "", type: "" };
              this.type = "add";
              this.$refs.form.reset();
              this.getData();
            },
          });
        })
        .catch((err) => {
          this.$alert({
            type: "warning",
            title: "Warning",
            message: "Failed add classification",
            button: {
              yes: "okay",
            },
            callback: () => {
              this.form = { ...this.form, name: "", code: "", type: "" };
              this.type = "add";
              this.$refs.form.reset();
              this.getData();
            },
          });
        });
    },
    updateClassification() {
      axios({
        method: "PUT",
        url: "/eb-api/eb-asset/update-data",
        data: {
          id: this.id,
          name: this.form.name,
          code: this.form.code,
          type: this.form.type,
          filter_type: "atk",
        },
      })
        .then((res) => {
          this.form = { ...this.form, name: "", code: "", type: "" };
          this.type = "add";
          this.$alert({
            type: "success",
            title: "Success",
            message: "Success update classification",
            button: {
              yes: "yes",
            },
            callback: () => {
              this.$refs.form.reset();
              this.getData();
            },
          });
        })
        .catch((err) => {
          this.$alert({
            type: "warning",
            title: "Warning",
            message: "Failed update classification",
            button: {
              yes: "okay",
            },
            callback: () => {
              this.form = { ...this.form, name: "", code: "", type: "" };
              this.type = "add";
              this.getData();
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  justify-content: space-between;
}
</style>
