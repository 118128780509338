import axios from "axios";
import router from "@/router";
import { setAccount } from "@/helpers/account";

export default {
  namespaced: true,
  state: {
    employeeData: {
      status: false,
      data: [],
    },
  },
  actions: {
    async getEmployee({ state }, data) {
      state.employeeData = {
        ...state.employeeData,
        status: true,
      };

      try {
        await axios({
          method: "POST",
          url: "/employee-data/attendance/read_data",
          data: {
            page: "1",
            query: [
              {
                field: "name",
                value: data,
              },
            ],
            size: "10",
          },
        }).then((res) => {
          let response = res.data.data;
          state.employeeData = {
            ...state.employeeData,
            status: false,
            data: [...response.Employee_List],
          };
        });
      } catch (err) {
        if (err.response.status == 401) {
          state.employeeData = {
            ...state.employeeData,
            status: false,
          };
        }
      }
    },
  },
};
