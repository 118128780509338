import axios from "axios";

export default {
  namespaced: true,
  state: {
    classificationData: {
      data: {},
      loading: false,
    },
  },
  actions: {
    async getData({ state }) {
      state.classificationData = {
        ...state.classificationData,
        loading: true,
      };

      try {
        await axios({
          method: "GET",
          url: "/eb-api/eb-asset/get-data",
          params: {
            type: 'atk'
          }
        }).then((res) => {
          let resp = res.data.data;
          state.classificationData.data = resp;
        });

        state.classificationData = {
          ...state.classificationData,
          loading: false,
        };
      } catch (error) {
        state.classificationData = {
          ...state.classificationData,
          loading: false,
        };
      }
    },
  },
};
