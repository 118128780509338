<template>
  <DialogCard v-model="showDialog">
    <template #title> Detail Dialog </template>

    <div class="content d-flex flex-column gap-6 ma-3">
      <v-row>
        <v-col
          v-for="(item, index) of menu"
          :key="index"
          class="info-wrap"
          cols="12"
          sm="4"
        >
          <p class="info-title">{{ item.name }}</p>
          <div class="d-flex flex-column" style="gap: 0.5rem">
            <div
              v-for="(item, index) of item.value"
              :key="index"
              class="d-flex"
            >
              <p class="key">{{ item.name }}</p>
              <p
                class="value"
                v-if="item.value == 'updated_at' || item.value == 'created_at'"
              >
                {{ params[item.value] | moment("MMMM DD, YYYY. HH:mm A") }}
              </p>
              <p class="value" v-else-if="item.value == 'purchase_date'">
                {{ params[item.value] | moment("MMMM DD, YYYY") }}
              </p>
              <p class="value" v-else>
                {{ params[item.value] }}
              </p>
            </div>
          </div>
        </v-col>
      </v-row>

      <div v-if="!params?.image?.length < 1" class="d-flex flex-column gap-3">
        <p class="info-title">Asset Image</p>
        <v-row>
          <v-col v-for="(images, index) of params?.image" :key="index" cols="4">
            <expandable-image
              class="image"
              close-on-background-click
              v-bind:src="`data:image/jpeg;base64,${images}`"
            ></expandable-image>
          </v-col>
        </v-row>
      </div>

      <div v-if="!params?.invoice?.length < 1" class="d-flex flex-column gap-3">
        <p class="info-title">Invoice Image</p>
        <v-row>
          <v-col
            v-for="(images, index) of params?.invoice"
            :key="index"
            cols="4"
          >
            <expandable-image
              class="image"
              close-on-background-click
              v-bind:src="`data:image/jpeg;base64,${images}`"
            ></expandable-image>
          </v-col>
        </v-row>
      </div>
    </div>
  </DialogCard>
</template>

<script>
import DialogCard from "@/components/base/dialog/dialog-card.vue";
import { ATK_DETAIL } from "@/config/atk-detail";

export default {
  name: "add-classification-dialog",
  data() {
    return {
      menu: [],
    };
  },
  props: ["value", "params"],
  components: {
    DialogCard,
  },
  async mounted() {},
  watch: {
    params(newValue, oldValue) {
      let category = ATK_DETAIL.map((item) => item.category).filter(
        (item, index, self) => self.indexOf(item) == index
      );
      let result = new Array();

      category.forEach((a) =>
        result.push({
          name: a,
          value: ATK_DETAIL.filter((b) => b.category == a),
        })
      );
      console.log(result);
      this.menu = result;
    },
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.row {
  justify-content: space-between;
}
.info-title {
  font-size: 14px;
  font-weight: $font-semibold;
}

.content {
  .info-wrap {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .key {
      flex-basis: 33.3% !important;
      font-size: 14px;
    }
    .value {
      padding-left: 10%;
      flex-basis: 66.6% !important;
      font-size: 14px;
      font-weight: $font-semibold;
    }
  }
}

.image {
  &::v-deep(img) {
    max-height: 300px;
  }

  &.expanded {
    &::v-deep(img) {
      height: 100%;
    }
  }
}
</style>
