<template>
  <DialogCard v-model="showDialog">
    <template #title> Add New User </template>

    <v-form ref="form" v-model="valid">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <BaseTextField height="20px" outlined :rules="[(v) => !!v || 'username is required']"
              v-model="form.username">
              <p>Username <b class="red--text">*</b></p>
            </BaseTextField>
          </v-col>
          <v-col cols="12" sm="6">
            <BaseTextField height="20px" outlined :rules="[(v) => !!v || 'fullname is required']"
              v-model="form.fullname">
              <p>Full Name <b class="red--text">*</b></p>
            </BaseTextField>
          </v-col>
          <v-col cols="12" sm="6">
            <BaseTextField height="20px" outlined hide-details="auto" :rules="[
              (v) => !!v || 'email is required',
              (v) =>
                !v ||
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                'E-mail must be valid',
            ]" v-model="form.email">
              <p>Email <b class="red--text">*</b></p>
            </BaseTextField>
          </v-col>
          <v-col cols="12" sm="6">
            <BaseSelect height="20px" outlined item-text="name" item-value="value" :items="[
              { name: 'Admin', value: 1 },
              { name: 'User', value: 4 },
            ]" :rules="[(v) => !!v || 'level is required']" v-model="form.level">
              <p>Level <b class="red--text">*</b></p>
            </BaseSelect>
          </v-col>
          <v-col cols="12" sm="4">
            <BaseCheckbox label="Asset Feature" :rules="[(v) => !!v || 'level is required']"
              v-model="form.menu.is_asset_feature"></BaseCheckbox>
          </v-col>
          <v-col cols="12" sm="4">
            <BaseCheckbox label="Stationary Feature" :rules="[(v) => !!v || 'level is required']"
              v-model="form.menu.is_stationary_feature"></BaseCheckbox>
          </v-col>
          <!-- <v-col cols="12" sm="4">
            <BaseTextField
              height="20px"
              outlined
              type="password"
              hide-details="auto"
              :rules="[(v) => !!v || 'password is required']"
              v-model="form.password"
            >
              <p>Password <b class="red--text">*</b></p>
            </BaseTextField>
          </v-col> -->
        </v-row>
      </v-container>
    </v-form>

    <template #action>
      <v-container class="d-flex flex-row gap-2">
        <BaseButton title="Submit" color="primary" height="40px" depressed rounded @click="handleSubmit()" />
        <BaseButton title="Cancel" height="40px" color="primary" outlined depressed rounded
          @click="showDialog = !showDialog" />
      </v-container>
    </template>
  </DialogCard>
</template>

<script>
import DialogCard from "@/components/base/dialog/dialog-card.vue";
import BaseTextField from "@/components/base/input/base-text-field.vue";
import BaseButton from "@/components/base/button/base-button.vue";
import BaseSelect from "@/components/base/input/base-select.vue";
import BaseCheckbox from "@/components/base/input/base-checkbox.vue";
import axios from "axios";

export default {
  components: {
    DialogCard,
    BaseTextField,
    BaseSelect,
    BaseButton,
    BaseCheckbox,
  },
  props: ["value", "params"],
  data() {
    return {
      valid: false,
      form: {
        username: "",
        fullname: "",
        email: "",
        level: 0,
        password: "",
        menu: {
          is_asset_feature: true,
          is_stationary_feature: true,
        },
      },
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    params(oldVal, newVal) {
      this.form = { ...this.form, username: this.params.username, fullname: this.params.fullname, email: this.params.email, level: parseInt(this.params.level) }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.validate();

      if (this.valid) {
        axios({
          method: "PUT",
          url: "/eb-api/ebasset-management/user/update",
          data: {
            username: this.form.username,
            full_name: this.form.fullname,
            email: this.form.email,
            level: this.form.level,
            menu: this.form.menu,
          },
        })
          .then((res) => {
            this.$alert({
              type: "success",
              title: "Success",
              message: res.data.message,
              button: {
                yes: "okay",
              },
              callback: () => {
                this.$refs.form.reset();
                this.showDialog = false;
                this.$emit("onsuccess");
              },
            });
          })
          .catch((err) => {
            this.$alert({
              type: "warning",
              title: "Warning",
              message: err.response.data.message,
              button: {
                yes: "okay",
              },
              callback: () => { },
            });
          });
      }
    },
  },
};
</script>

<style></style>
