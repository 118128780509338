<template>
  <v-app>
    <AlertDialog />
    <Drawer />
    <Navbar />

    <v-main>
      <v-container class="pa-6" fluid>
        <div class="main d-flex flex-column">
          <router-view />
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Drawer from "../core/drawer/drawer.vue";
import Navbar from "../core/navbar/navbar.vue";

export default {
  name: "dashboard-layout",
  components: {
    Drawer,
    Navbar,
  },
};
</script>

<style lang="scss" scoped>
.v-main {
  background: var(--v-accent-base);

  .main {
    gap: 1rem;

    h5 {
      font-family: Comfortaa, cursive;
      font-size: 18px !important;
      font-weight: $font-black;
      text-transform: capitalize;
    }
  }
}
</style>
