<template>
  <v-menu
    class="menu"
    transition="scale-transition"
    offset-y
    v-model="menu"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        class="text-field-datepicker"
        readonly
        dense
        outlined
        clearable
        height="45"
        prepend-inner-icon="mdi-calendar"
        v-model="dateComputed"
        v-on="on"
        :label="label"
        :hide-details="hideDetails"
        :disabled="disabled"
        :rules="rules"
        v-on:input="$emit('input', $event)"
      >
        <template v-slot:label>
          <slot></slot>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      color="primary"
      no-title
      v-model="selected"
      :min="min"
      :max="max"
      :show-current="showCurrent"
      @input="menu = false"
    />
  </v-menu>
</template>

<script>
import moment from "moment";

export default {
  name: "datepicker2",
  props: [
    "value",
    "label",
    "innerIcon",
    "hideDetails",
    "min",
    "max",
    "showCurrent",
    "disabled",
    "rules",
  ],
  data() {
    return {
      menu: null,
      date: null,
    };
  },

  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    dateComputed: {
      get() {
        return this.formatDate(this.value);
      },
      set(value) {
        return this.$emit("input", value);
      },
    },
  },
  methods: {
    formatDate(date) {
      if (!date) {
        return null;
      }

      // const [year, month, day] = date.split("-");
      return moment(date).format("D MMMM YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-menu__content {
  box-shadow: none !important;
}
.text-field-datepicker {
  font-family: $secondary-font;
  font-size: 0.8rem;
  border-radius: 18px !important;

  ::v-deep(.v-input__slot) {
    .v-label {
      top: auto !important;
      bottom: auto !important;
      font-size: 14px;

      &--active {
        transform: translateY(-23px) scale(0.75) !important;
      }
    }

    .v-input__prepend-inner {
      margin-top: auto !important;
      margin-bottom: auto !important;

      .v-icon {
        font-size: 18px !important;
      }
    }

    .v-input__append-inner {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }

    .v-input__icon--clear {
      button {
        font-size: 14px !important;
      }
    }
  }
}
</style>
