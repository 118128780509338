<template>
  <v-combobox
    class="custom-select"
    flat
    dense
    hide-spin-buttons
    :menu-props="{ offsetY: true, overflowX: true }"
    :background-color="background"
    :disabled="disabled"
    :clearable="clearable"
    :color="color"
    :label="label"
    :placeholder="placeholder"
    :item-text="itemText"
    :item-value="itemValue"
    :rules="rules"
    :outlined="outlined"
    :items="items"
    :loading="loading"
    :height="height"
    :hide-details="hideDetails"
    :return-object="returnObject"
    :error="error"
    :error-messages="errorMessages"
    v-model="selected"
    v-on="$listeners"
  >
    <template v-slot:label>
      <slot></slot>
    </template>
  </v-combobox>
</template>

<script>
export default {
  name: "combobox",
  props: [
    "value",
    "background",
    "disabled",
    "label",
    "color",
    "placeholder",
    "itemText",
    "itemValue",
    "rules",
    "items",
    "loading",
    "outlined",
    "height",
    "hideDetails",
    "error",
    "clearable",
    "errorMessages",
    "returnObject",
  ],
  data() {
    return {};
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-select {
  font-family: $secondary-font;
  font-size: 0.8rem !important;
  border-radius: 18px !important;

  ::v-deep(.v-select__slot) {
    .v-label {
      top: auto !important;
      bottom: auto !important;

      &--active {
        transform: translateY(-23px) scale(0.75) !important;
      }
    }

    .v-input__append-inner {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    
    .v-input__icon--clear {
      button {
        font-size: 14px !important;
      }
    }
  }
}
</style>
