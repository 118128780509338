<template>
  <v-menu
    content-class="menu elevation-0"
    :close-on-content-click="false"
    offset-y
    left
  >
    <template v-slot:activator="{ on }">
      <BaseButtonIcon
        icon="iconoir-settings"
        outlined
        v-on="on"
      ></BaseButtonIcon>
    </template>

    <BaseCard>
      <div class="pa-6 d-flex flex-column" style="gap: 1rem">
        <div>
          <p>Interval</p>
          <v-btn-toggle mandatory color="primary" v-model="filterData.interval">
            <v-btn class="font-3" value="1d">1D</v-btn>
            <v-btn class="font-3" value="1M">1M</v-btn>
            <v-btn class="font-3" value="1y">1Y</v-btn>
          </v-btn-toggle>
        </div>
        <BaseDatepicker
          label="start date"
          v-model="filterData.start"
          :showCurrent="false"
          :max="filterData.end"
        />

        <BaseDatepicker
          label="end date"
          v-model="filterData.end"
          :showCurrent="false"
          :min="filterData.start"
        />

        <BaseButton
          class="mt-4 font-3"
          title="Apply"
          color="primary"
          depressed
          @click="emitApply()"
        />
      </div>
    </BaseCard>
  </v-menu>
</template>

<script>
import BaseCard from "../card/base-card.vue";
import BaseButtonIcon from "../button/base-button-icon.vue";
import BaseDatepicker from "../input/base-datepicker.vue";
import BaseButton from "../button/base-button.vue";

export default {
  name: "filter-chart",
  components: {
    BaseCard,
    BaseButtonIcon,
    BaseDatepicker,
    BaseButton,
  },
  data() {
    return {
      filterData: {
        start: "2022-01-01",
        end: "2022-12-31",
        interval: "1M",
      },
    };
  },
  mounted() {
    const currentDate = new Date();
    const firstDateOfFirstMonth = new Date(currentDate.getFullYear(), 0, 1);
    const lastDateOfLastMonth = new Date(currentDate.getFullYear(), 12, -1);

    const parsedDateStart = new Date(firstDateOfFirstMonth);
    const parsedDateLast = new Date(lastDateOfLastMonth);

    const startDate = `${parsedDateStart.getFullYear()}-${(parsedDateStart.getMonth() + 1).toString().padStart(2, '0')}-${parsedDateStart.getDate().toString().padStart(2, '0')}`;
    const endDate = `${parsedDateLast.getFullYear()}-${(parsedDateLast.getMonth() + 1).toString().padStart(2, '0')}-${parsedDateLast.getDate().toString().padStart(2, '0')}`;

    this.filterData.start = startDate
    this.filterData.end = endDate
    this.emitApply();
  },
  methods: {
    emitApply() {
      this.$emit("change", this.filterData);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  font-size: 12px;
}
</style>
>
