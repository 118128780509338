import axios from "axios";
import router from "@/router";
import { setAccount } from "@/helpers/account";

export default {
  namespaced: true,
  state: {
    loginError: {
      status: false,
      text: "",
    },
  },
  actions: {
    async handleLogin({ state }, data) {
      state.loginError = { ...state.loginError, status: false, text: "" };

      try {
        await axios({
          method: "POST",
          url: "/eb-api/ebasset-management/user/login",
          data: {
            username: data.username,
            password: data.password,
          },
        }).then((res) => {
          let response = res.data.data;
          setAccount(response, data.savePassword ? "local" : "session");
          router.push({name: 'overview'})
        });
      } catch (err) {
        if (err.response.status == 401) {
          state.loginError = {
            ...state.loginError,
            status: true,
            text: err.response.data.message,
          };
        }
      }
    },
  },
};
