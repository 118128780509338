import axios from "axios";

export default {
  namespaced: true,
  state: {
    assetData: {
      data: [],
      loading: false,
    },
  },
  actions: {
    async getAsset({ state }, data) {
      state.assetData = {
        ...state.assetData,
        loading: true,
      };

      try {
        await axios({
          method: "POST",
          url: "/eb-api/eb-asset/get-asset",
          params: {
            page: data.page || "1",
            size: data.size == -1 ? 10000 : data.size || "5",
            keyword: data.search || "*",
            start: data.start || "*",
            end: data.end || "*",
            type: data.type || "*",
            filter: data.filter || "*",
            sort: data.sort || "desc",
            sort_by:
              data?.sort_by == "purchase_date"
                ? `${data.sort_by}`
                : `${data?.sort_by}` == "undefined"
                ? "created_at"
                : `${data.sort_by}.keyword`,
          },
        }).then((res) => {
          let resp = res.data.data;
          state.assetData.data = resp;
        });

        state.assetData = {
          ...state.assetData,
          loading: false,
        };
      } catch (error) {
        state.assetData = {
          ...state.assetData,
          loading: false,
        };
      }
    },
  },
};
