export const ATK_DETAIL = [
  {
    category: "Item Type",
    name: "Type",
    value: "type",
  },
  {
    category: "Item Type",
    name: "Category",
    value: "category",
  },
  {
    category: "Item Type",
    name: "Sub Category",
    value: "sub_category",
  },
  {
    category: "Item Info",
    name: "Name",
    value: "name",
  },
  {
    category: "Item Info",
    name: "Brand",
    value: "brand",
  },
  {
    category: "Item Info",
    name: "Division",
    value: "division",
  },
  {
    category: "Item Info",
    name: "Location",
    value: "location",
  },
  {
    category: "Item Info",
    name: "Price",
    value: "price",
  },
  {
    category: "Item Info",
    name: "Quantity",
    value: "qty",
  },
  {
    category: "Item Description",
    name: "Purchase",
    value: "purchase_date",
  },
  {
    category: "Item Description",
    name: "Created",
    value: "created_at",
  },
  {
    category: "Item Description",
    name: "Updated",
    value: "updated_at",
  },
];
