<template>
  <v-btn
    class="custom-button"
    icon
    :absolute="absolute"
    :block="block"
    :color="color"
    :dark="dark"
    :depressed="depressed"
    :disabled="disabled"
    :fixed="fixed"
    :height="height"
    :large="large"
    :outlined="outlined"
    :plain="plain"
    :ripple="ripple"
    :rounded="rounded"
    :small="small"
    :to="to"
    :text="text"
    :width="width"
    :value="value"
    v-on="$listeners"
  >
    <v-icon>{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "BaseIconButton",
  props: [
    "value",
    "absolute",
    "block",
    "color",
    "dark",
    "depressed",
    "disabled",
    "fixed",
    "height",
    "large",
    "outlined",
    "plain",
    "ripple",
    "rounded",
    "small",
    "to",
    "text",
    "width",
    "title",
    "icon",
  ],
};
</script>

<style lang="scss" scoped>
.custom-button {
  .v-icon {
    font-size: 15px !important;
  }

  &:focus {
    outline: 0;
  }
}
</style>
