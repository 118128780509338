<template>
  <div>
    <v-file-input
      class="custom-base-input"
      small-chips
      show-size
      :placeholder="placeholder"
      :multiple="multiple"
      :disabled="disabled"
      :counter="counter"
      :value="models"
      :outlined="outlined"
      :hide-details="hideDetails"
      :rules="rules"
      :prepend-inner-icon="prependInnerIcon"
      prepend-icon=""
      v-on="$listeners"
    >
      <template #label>
        <slot />
      </template>
    </v-file-input>
  </div>
</template>

<script>
export default {
  name: "base-file-input",
  props: [
    "multiple",
    "disabled",
    "counter",
    "value",
    "outlined",
    "height",
    "hideDetails",
    "rules",
    "prependInnerIcon",
    "placeholder"
  ],
  computed: {
    models: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-base-input ::v-deep(.v-input__control) .v-input__slot {
  border-radius: 18px !important;
  min-height: v-bind(height) !important;
  display: flex !important;
  align-items: center !important;

  
    .v-label {
      font-size: 14px;
      top: auto !important;
      bottom: auto !important;

      &--active {
        transform: translateY(-23px) scale(0.75) !important;
      }
    }

  .v-input__prepend-inner {
    margin-top: auto;
    margin-bottom: auto;

    i {
      font-size: 18px;
    }
  }

  .v-input__append-inner {
    margin-top: auto !important;
    margin-bottom: auto !important;

    .v-icon {
      font-size: 18px !important;
    }
  }
}
</style>
