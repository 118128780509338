<template>
  <v-navigation-drawer v-model="drawerState" color="primary" width="250px" dark app>
    <template #prepend>
      <div id="title">
        <h1 class="title white--text text-center">eBassets</h1>
      </div>
    </template>
    <div id="layout-menu" class="mt-5" v-for="n of menuitem" :key="n.name">
      <div v-if="level == 1 ? true : !n.admin && level == 4">
        <div class="menu-category py-4">
          {{ n.name }}
        </div>

        <div v-for="a of n.routes" :key="a.length">
          <List v-if="!!a.child" :activator-title="a.name" :icon="a.icon">
            <ListItemIcon v-for="b of a.child" :key="b" class="ml-5" :icon="b.icon" :title="b.name" />
          </List>

          <ListItemIcon v-if="!a.child && a.category !== 'Admin Menu' && a.visibility" class="ml-5" :icon="a.icon"
            :title="a.name" :to="a.complete_path" />

          <ListItemNogroup v-if="a.category == 'Admin Menu'" class="mx-5" :icon="a.icon" :title="a.name"
            :to="a.complete_path" />
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import ListItemIcon from "./component/list-item-icon.vue";
import ListitemImage from "./component/list-item-image.vue";
import ListItemNogroup from "./component/list-item-nogroup.vue";
import List from "./component/list.vue";
import { mapState } from "vuex";
import { APP_SIDEBAR } from "@/config/app-sidebar";
import { getAccount } from "@/helpers/account";

export default {
  name: "Drawer",
  data() {
    return {
      menuitem: [],
      level: getAccount().level,
    };
  },
  created() {
    // let category = APP_SIDEBAR.map((item) => item.category).filter(
    //   (item, index, self) => self.indexOf(item) == index
    // );

    // let result = new Array();

    // category.forEach((a) =>
    //   result.push({
    //     name: a,
    //     admin: a.includes("Admin"),
    //     routes: APP_SIDEBAR.filter((b) => b.category == a),
    //   })
    // );
    // this.menuItem = result;

    let data2 = getAccount().menu || {}; // Tambahkan fallback default untuk mencegah null

    const result = Object.values(
      APP_SIDEBAR.reduce((acc, cur) => {
        const category = cur.category;
        if (!acc[category]) {
          acc[category] = {
            name: category,
            admin: cur.admin || false,
            routes: [],
          };
        }
        acc[category].routes.push({
          ...cur,
          visibility: data2[cur.visibility] ?? true, // Pastikan properti visibility aman
        });
        return acc;
      }, {})
    );
    this.menuitem = result; // Gunakan 'menuitem' sesuai dengan data() 
  },
  computed: {
    drawerState: {
      get() {
        return this.$store.getters["drawer/drawerState"];
      },
      set(v) {
        return this.$store.commit("drawer/toggleDrawerState", v);
      },
    },
  },
  components: {
    List,
    ListitemImage,
    ListItemIcon,
    ListItemNogroup,
  },
};
</script>

<style lang="scss" scoped>
.v-navigation-drawer {
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.264);
}

#title {
  padding: 30px 24px 24px;

  h1 {
    font-family: $primary-font !important;
    font-size: 30px !important;
  }
}

#layout-menu {
  .menu-category {
    display: flex;
    align-items: center;
    height: 48px;
    text-transform: capitalize;
    line-height: 20px;
    font-weight: 500;
    font-size: 12px !important;
    color: hsla(0, 0%, 100%, 0.71);
    margin-left: 0.5rem;
  }
}
</style>
