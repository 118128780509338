<template>
  <div id="wrapper" class="d-flex flex-column">
    <DetailAsset />
    <DetailTable />
    <DetailAssetPurchase />

    <v-row>
      <v-col>
        <InfoCard
          title="Hardware Total"
          description="Units"
          :value="countData.hardware"
        ></InfoCard>
      </v-col>
      <v-col>
        <InfoCard
          title="Software Total"
          description="Units"
          :value="countData.software"
        ></InfoCard>
      </v-col>
    </v-row>

    <div class="purchase-asset" style="height: 500px">
      <div class="d-flex justify-space-between">
        <h4 class="align-self-center">Asset Purchase Timerange</h4>
        <FilterChart @change="applyFilter($event)" />
      </div>
      <BaseEchart
        :loading="purchaseData.loading"
        :chartOpt="barOpt"
        @click="log($event)"
      ></BaseEchart>
    </div>

    <v-row no-gutters>
      <v-col cols="12" sm="4">
        <div
          class="purchase-asset d-flex flex-column gap-3"
          style="height: 500px"
        >
          <div class="d-flex justify-space-between">
            <h4 class="align-self-center">Asset Category</h4>
          </div>
          <BaseEchart
            :loading="categoryData.loading"
            :chartOpt="pieAssetCategory"
            @click="
              detailPieChart(
                $event.data.name,
                'sub_category',
                'detail-subcategory-chart'
              )
            "
          ></BaseEchart>
        </div>
      </v-col>

      <v-col cols="12" sm="4">
        <div
          class="purchase-asset d-flex flex-column gap-3"
          style="height: 500px"
        >
          <div class="d-flex justify-space-between">
            <h4 class="align-self-center">Asset Brand</h4>
          </div>
          <BaseEchart
            :loading="brandData.loading"
            :chartOpt="pieAssetBrand"
            @click="detailPieChart($event.data.name, 'status', 'detail-brand')"
          ></BaseEchart>
        </div>
      </v-col>

      <v-col cols="12" sm="4">
        <div
          class="purchase-asset d-flex flex-column gap-3"
          style="height: 500px"
        >
          <div class="d-flex justify-space-between">
            <h4 class="align-self-center">Asset Status</h4>
          </div>
          <BaseEchart
            :loading="statusData.loading"
            :chartOpt="pieAssetStatus"
            @click="
              detailPieChart(
                $event.data.name,
                'status',
                'detail-subcategory-chart'
              )
            "
          ></BaseEchart>
        </div>
      </v-col>
    </v-row>

    <div>
      <div class="purchase-asset d-flex flex-column gap-3">
        <div class="d-flex justify-space-between">
          <h4 class="align-self-center">Employee Asset</h4>
        </div>
        <v-row>
          <v-col cols="12" sm="3">
            <BaseTextField
              append-icon="iconoir-search"
              height="20px"
              placeholder="Search"
              outlined
              v-model="employeeAssetSearch"
            />
          </v-col>
        </v-row>
        <TableData
          :height="400"
          :headers="headerEmployeeAsset"
          :items="employeeTableData.data"
          :itemsPerPageOptions="[5, 10, 20, -1]"
          :search="employeeAssetSearch"
          @click:row="showDetailTable($event, 'employee')"
        ></TableData>
      </div>
    </div>

    <div>
      <div class="purchase-asset d-flex flex-column gap-3">
        <div class="d-flex justify-space-between">
          <h4 class="align-self-center">Server Asset</h4>
        </div>
        <v-row>
          <v-col cols="12" sm="3">
            <BaseTextField
              append-icon="iconoir-search"
              height="20px"
              placeholder="Search"
              outlined
              v-model="serverAssetSearch"
            />
          </v-col>
        </v-row>
        <TableData
          :height="400"
          :headers="headerServerAsset"
          :items="serverTableData.data"
          :itemsPerPageOptions="[5, 10, 20, -1]"
          :search="serverAssetSearch"
          @click:row="showDetailTable($event, 'server')"
        ></TableData>
      </div>
    </div>

    <div>
      <div class="purchase-asset d-flex flex-column gap-3">
        <div class="d-flex justify-space-between">
          <h4 class="align-self-center">Asset Status Table</h4>
        </div>
        <v-row>
          <v-col cols="12" sm="3">
            <BaseTextField
              append-icon="iconoir-search"
              height="20px"
              placeholder="Search"
              outlined
              v-model="statusAssetSearch"
            />
          </v-col>
        </v-row>
        <TableData
          :height="400"
          :headers="headerStatusAsset"
          :items="statusTableData.data"
          :itemsPerPageOptions="[5, 10, 20, -1]"
          :search="statusAssetSearch"
          @click:row="
            detailAssetTabs($event.name, 'sub_category', 'detail-status-table')
          "
        ></TableData>
      </div>
    </div>
  </div>
</template>

<script>
import BaseEchart from "@/components/base/chart/base-echart.vue";
import BaseCard from "@/components/base/card/base-card.vue";
import InfoCard from "@/components/base/card/info-card.vue";
import TableData from "@/components/base/table/table-data.vue";
import FilterChart from "@/components/base/filter/filter-chart.vue";
import BaseTextField from "@/components/base/input/base-text-field.vue";
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      employeeAssetSearch: "",
      serverAssetSearch: "",
      statusAssetSearch: "",
      purchaseFilter: undefined,
      headerEmployeeAsset: [
        {
          text: "Employee Name",
          value: "name",
          align: "left",
          width: "35%",
          filterable: true,
        },
        {
          text: "Assets",
          value: "asset",
          align: "left",
          width: "50%",
        },
        {
          text: "Total Assets",
          value: "total",
          align: "left",
          width: "15%",
        },
      ],
      headerServerAsset: [
        {
          text: "IP Address",
          value: "ip_address",
          align: "left",
          width: "30%",
          filterable: true,
        },
        {
          text: "Assets",
          value: "asset",
          align: "left",
          width: "30%",
        },
        {
          text: "Total Assets",
          value: "total",
          align: "left",
          width: "40%",
        },
      ],
      headerStatusAsset: [
        {
          text: "Asset Name",
          value: "name",
          align: "left",
          width: "25%",
          filterable: true,
        },
        {
          text: "Used",
          value: "used",
          align: "left",
          width: "15%",
        },
        {
          text: "Damaged",
          value: "damaged",
          align: "left",
          width: "15%",
        },
        {
          text: "Fixing",
          value: "fixing",
          align: "left",
          width: "15%",
        },
        {
          text: "Available",
          value: "available",
          align: "left",
          width: "15%",
        },
        {
          text: "Total Assets",
          value: "total",
          align: "left",
          width: "15%",
        },
      ],
    };
  },
  components: {
    BaseCard,
    InfoCard,
    TableData,
    BaseEchart,
    FilterChart,
    BaseTextField,
  },
  created() {
    this.handleCountAsset("software");
    this.handleCountAsset("hardware");
    this.categoryAsset();
    this.brandAsset();
    this.statusAsset();
    this.employeeTableAsset();
    this.serverTableAsset();
    this.statusTableAsset();
  },
  computed: {
    ...mapState("dashboardCountAsset", ["countData"]),
    ...mapState("dashboardPurchase", ["purchaseData"]),
    ...mapState("dashboardCategory", ["categoryData"]),
    ...mapState("dashboardBrand", ["brandData"]),
    ...mapState("dashboardStatus", ["statusData"]),
    ...mapState("dashboardEmployeeTableAsset", ["employeeTableData"]),
    ...mapState("dashboardServerTableAsset", ["serverTableData"]),
    ...mapState("dashboardStatusTableAsset", ["statusTableData"]),
    barOpt() {
      return {
        tooltip: {
          valueFormatter: (value) => `${value} units`,
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.purchaseData.key,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Total Purchasing",
            type: "bar",
            barWidth: "60%",
            data: this.purchaseData.count,
          },
        ],
      };
    },
    pieAssetCategory() {
      return {
        tooltip: {
          trigger: "item",
        },
        left: "right",
        series: [
          {
            name: "Total Assets",
            type: "pie",
            selectedMode: "single",
            radius: [0, "30%"],
            label: {
              position: "inner",
              fontSize: 14,
            },
            labelLine: {
              show: false,
            },
            data: this.categoryData.series1,
          },
          {
            name: "Total Assets",
            type: "pie",
            radius: ["40%", "60%"],
            labelLine: {
              length: 0.1,
            },
            data: this.categoryData.series2,
          },
        ],
      };
    },
    pieAssetBrand() {
      return {
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "Total Assets",
            type: "pie",
            radius: "60%",
            data: this.brandData.data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
    pieAssetStatus() {
      return {
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "Assets Status",
            type: "pie",
            radius: "60%",
            data: this.statusData.data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
  },
  methods: {
    ...mapActions("dashboardPurchase", ["purchaseAsset"]),
    ...mapActions("dashboardCountAsset", ["countAsset"]),
    ...mapActions("dashboardCategory", ["categoryAsset"]),
    ...mapActions("dashboardBrand", ["brandAsset"]),
    ...mapActions("dashboardStatus", ["statusAsset"]),
    ...mapActions("dashboardEmployeeTableAsset", ["employeeTableAsset"]),
    ...mapActions("dashboardServerTableAsset", ["serverTableAsset"]),
    ...mapActions("dashboardStatusTableAsset", ["statusTableAsset"]),
    log(e) {
      let params = { ...this.purchaseFilter };

      this.$detailPurchase({
        title: e.name,
        filter: true,
        params: params,
      });
    },
    showDetailTable(e, type) {
      this.$detailTable({
        title: e.name ?? e.ip_address,
        params: {
          type: type,
          value: e.name ?? e.ip_address
        }
      });
    },
    handleCountAsset(type) {
      this.countAsset(type);
    },
    applyFilter(model) {
      this.purchaseFilter = model;
      this.purchaseAsset(model);
    },
    detailPieChart(e, param, url) {
      this.$detail({
        filter: true,
        title: e,
        url: url,
        params: {
          [param]: e,
        },
      });
    },
    detailAssetTabs(e, param, url) {
      this.$detail({
        type: "tabs",
        filter: true,
        title: e,
        url: url,
        params: {
          [param]: e,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#wrapper {
  gap: 2rem;

  .purchase-asset {
    h4 {
      font-size: 16px;
    }
  }
}
</style>
